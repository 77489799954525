import moment from 'moment-timezone';

const {
    REACT_APP_PLANNED_MAINTENANCE_START_DATE_TIME_UTC,
    REACT_APP_PLANNED_MAINTENANCE_END_DATE_TIME_UTC,
} = process.env;

const getMaintenancePeriod = () => {
    if(
        !REACT_APP_PLANNED_MAINTENANCE_START_DATE_TIME_UTC
        || !REACT_APP_PLANNED_MAINTENANCE_END_DATE_TIME_UTC
    ) {
        return null;
    }

    // MAINTENANCE START DATE TIME
    const start = moment.utc(REACT_APP_PLANNED_MAINTENANCE_START_DATE_TIME_UTC).tz('Europe/London');
    // e.g. "Thu August 29th"
    const startFormattedDate = start.format('ddd MMMM Do');
    // e.g. "8pm"
    const startFormattedTime = start.format('hh:mmA');

    // MAINTENANCE END DATE TIME
    const end = moment.utc(REACT_APP_PLANNED_MAINTENANCE_END_DATE_TIME_UTC).tz('Europe/London');
    // e.g. "Thu August 29th"
    const endFormattedDate = end.format('ddd MMMM Do');
    // e.g. "10pm"
    const endFormattedTime = end.format('hh:mmA');

    // Is maintenance period past
    const isPast = moment.utc().tz('Europe/London').isAfter(end);

    return {
        start,
        startFormattedDate,
        startFormattedTime,
        end,
        endFormattedDate,
        endFormattedTime,
        isPast,
    };
};

export default getMaintenancePeriod;
