import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as vehiclesApi from '../../api/vehicles'

export const getCurrentVehicle = createAsyncThunk(
    'vehicles/getCurrentVehicle',
    async (arg, {getState, requestId}) => {
        const {
            loadingCurrentVehicle,
            loadingCurrentVehicleCurrentRequestId
        } = getState().vehicles
      
        if (loadingCurrentVehicle !== 'pending' || requestId !== loadingCurrentVehicleCurrentRequestId) {
            return
        }
    
        const response = await vehiclesApi.getCurrentVehicle(getState().auth.token)
    
        return response.data.data
    }
)

export const getVehicles = createAsyncThunk(
    'vehicles/getVehicles',
    async (arg, {getState, requestId}) => {
        const { loadingAll, loadingAllCurrentRequestId } = getState().vehicles
      
        if (loadingAll !== 'pending' || requestId !== loadingAllCurrentRequestId) {
            return
        }
    
        const response = await vehiclesApi.getVehicles(getState().auth.token)
    
        return response.data.data
    }
)

export const removeVehicle = createAsyncThunk(
    'vehicles/remove',
    async (vehicleId, {getState, requestId}) => {
        const state = getState().vehicles

        if (
            state.deleting.hasOwnProperty(vehicleId)
            && (
                state.deleting[vehicleId].deleting !== 'pending'
                || state.deleting[vehicleId].currentRequestId !== requestId
            )
        ) {
            return
        }

        const response = await vehiclesApi.deleteVehicle(
            vehicleId,
            getState().auth.token
        )

        return response.data.data
    }
)

export const getVehicle = createAsyncThunk(
    'vehicles/getVehicle',
    async (vehicleId, {getState, requestId}) => {
        const state = getState().vehicles

        if (
            state.loading.hasOwnProperty(vehicleId)
            && (
                state.loading[vehicleId].loading !== 'pending'
                || state.loading[vehicleId].currentRequestId !== requestId
            )
        ) {
            return
        }
    
        const response = await vehiclesApi.getVehicle(
            vehicleId,
            getState().auth.token
        )
    
        return response.data.data
    }
)

const vehiclesSlice = createSlice({
    name: 'vehicles',
    initialState: {
        /**
         * All vehicles.
         * {
         *   [vehicle.id]: { ...vehicle.data }
         * }
         */
        vehicles: {},

        /**
         * Loading all.
         */
        loadingAll: 'idle',
        hasLoadedAll: false,
        loadingAllCurrentRequestId: undefined,
        loadingAllError: null,

        /**
         * Individual record loading status'.
         * {
         *   [vehicle.id]: {
         *     loading: 'idle'|'pending',
         *     error: Error|null,
         *     currentRequestId: string|undefined,
         *   }
         * }
         */
        loading: {},
        deleting: {},

        /**
         * Current vehicle.
         */
        currentVehicle: undefined,
        loadingCurrentVehicle: 'idle',
        loadingCurrentVehicleCurrentRequestId: undefined,
        loadingCurrentVehicleError: null
    },
    reducers: {},
    extraReducers: {
        [getVehicles.pending]: (state, action) => {
            if (state.loadingAll === 'idle') {
                state.loadingAll = 'pending'
                state.loadingAllCurrentRequestId = action.meta.requestId
            }
        },
        [getVehicles.fulfilled]: (state, action) => {
            const { requestId } = action.meta
            if (state.loadingAll === 'pending' && state.loadingAllCurrentRequestId === requestId) {
                state.loadingAll = 'idle'
                state.hasLoadedAll = true
                action.payload.forEach(vehicle => {
                    state.vehicles[vehicle.id] = vehicle
                })
                state.loadingAllCurrentRequestId = undefined
            }
        },
        [getVehicles.rejected]: (state, action) => {
            const { requestId } = action.meta
            if (state.loadingAll === 'pending' && state.loadingAllCurrentRequestId === requestId) {
                state.loadingAll = 'idle'
                state.loadingAllError = action.error
                state.loadingAllCurrentRequestId = undefined
            }
        },
        [getVehicle.pending]: (state, action) => {
            const { arg, requestId } = action.meta
            if (
                !state.loading.hasOwnProperty(arg)
                || state.loading[arg].loading === 'idle'
            ) {
                state.loading[arg] = {
                    loading: 'pending',
                    error: null,
                    currentRequestId: requestId
                }
            }
        },
        [getVehicle.fulfilled]: (state, action) => {
            const { arg, requestId } = action.meta
            if (
                state.loading.hasOwnProperty(arg)
                && state.loading[arg].loading === 'pending'
                && state.loading[arg].currentRequestId === requestId
            ) {
                state.loading[arg] = {
                    loading: 'idle',
                    error: null,
                    currentRequestId: undefined
                }
                state.vehicles[action.payload.id] = action.payload
            }
        },
        [getVehicle.rejected]: (state, action) => {
            const { arg, requestId } = action.meta
            if (
                state.loading.hasOwnProperty(arg)
                && state.loading[arg].loading === 'pending'
                && state.loading[arg].currentRequestId === requestId
            ) {
                state.loading[action.meta.arg] = {
                    loading: 'idle',
                    error: action.error,
                    currentRequestId: undefined
                }
            }
        },
        [getCurrentVehicle.pending]: (state, action) => {
            if (state.loadingCurrentVehicle === 'idle') {
                state.loadingCurrentVehicle = 'pending'
                state.loadingCurrentVehicleCurrentRequestId = action.meta.requestId
            }
        },
        [getCurrentVehicle.fulfilled]: (state, action) => {
            const { requestId } = action.meta
            if (state.loadingCurrentVehicle === 'pending' && state.loadingCurrentVehicleCurrentRequestId === requestId) {
                state.loadingCurrentVehicle = 'idle'
                state.currentVehicle = action.payload
                if (action.payload) {
                    state.vehicles[action.payload.id] = action.payload
                }
                state.loadingCurrentVehicleCurrentRequestId = undefined
            }
        },
        [getCurrentVehicle.rejected]: (state, action) => {
            const { requestId } = action.meta
            if (state.loadingCurrentVehicle === 'pending' && state.loadingCurrentVehicleCurrentRequestId === requestId) {
                state.loadingCurrentVehicle = 'idle'
                state.loadingCurrentVehicleError = action.error
                state.loadingCurrentVehicleCurrentRequestId = undefined
            }
        },
        [removeVehicle.pending]: (state, action) => {
            const { arg, requestId } = action.meta
            if (
                !state.deleting.hasOwnProperty(arg)
                || state.deleting[arg].deleting === 'idle'
            ) {
                state.deleting[arg] = {
                    deleting: 'pending',
                    error: null,
                    currentRequestId: requestId
                }
            }
        },
        [removeVehicle.fulfilled]: (state, action) => {
            const { arg, requestId } = action.meta
            if (
                state.deleting.hasOwnProperty(arg)
                && state.deleting[arg].deleting === 'pending'
                && state.deleting[arg].currentRequestId === requestId
            ) {
                state.deleting[arg] = {
                    deleting: 'idle',
                    error: null,
                    currentRequestId: undefined
                }
                delete state.vehicles[arg];
            }
        },
        [removeVehicle.rejected]: (state, action) => {
            const { arg, requestId } = action.meta

            if (
                state.deleting.hasOwnProperty(arg)
                && state.deleting[arg].deleting === 'pending'
                && state.deleting[arg].currentRequestId === requestId
            ) {
                state.deleting[action.meta.arg] = {
                    deleting: 'idle',
                    error: action.error,
                    currentRequestId: undefined
                }
            }
        },
    }
})

const { actions, reducer } = vehiclesSlice

export default reducer