import createActionName from '../createActionName';

export const reducerName = 'appointments';

export const ACCEPT_CHANGE_RESOURCE_REQUEST = createActionName(reducerName, 'ACCEPT_CHANGE_RESOURCE_REQUEST');
export const ACCEPT_CHANGE_RESOURCE_SUCCESS = createActionName(reducerName, 'ACCEPT_CHANGE_RESOURCE_SUCCESS');
export const ACCEPT_CHANGE_RESOURCE_ERROR = createActionName(reducerName, 'ACCEPT_CHANGE_RESOURCE_ERROR');
export const CANCEL_RESOURCE_REQUEST = createActionName(reducerName, 'CANCEL_RESOURCE_REQUEST');
export const CANCEL_RESOURCE_SUCCESS = createActionName(reducerName, 'CANCEL_RESOURCE_SUCCESS');
export const CANCEL_RESOURCE_ERROR = createActionName(reducerName, 'CANCEL_RESOURCE_ERROR');
export const CHANGE_PAGE_RESOURCES = createActionName(reducerName, 'CHANGE_PAGE_RESOURCES');
export const CLEAR_METADATA_RESOURCES = createActionName(reducerName, 'CLEAR_METADATA_RESOURCES');
export const CLEAR_METADATA_RESOURCE_EDIT = createActionName(reducerName, 'CLEAR_METADATA_RESOURCE_EDIT');
export const DESTROY_RESOURCE_REQUEST = createActionName(reducerName, 'DESTROY_RESOURCE_REQUEST');
export const DESTROY_RESOURCE_SUCCESS = createActionName(reducerName, 'DESTROY_RESOURCE_SUCCESS');
export const DESTROY_RESOURCE_ERROR = createActionName(reducerName, 'DESTROY_RESOURCE_ERROR');
export const FIND_RESOURCE_REQUEST = createActionName(reducerName, 'FIND_RESOURCE_REQUEST');
export const FIND_RESOURCE_SUCCESS = createActionName(reducerName, 'FIND_RESOURCE_SUCCESS');
export const FIND_RESOURCE_ERROR = createActionName(reducerName, 'FIND_RESOURCE_ERROR');
export const FIND_NEXT_RESOURCE_REQUEST = createActionName(reducerName, 'FIND_NEXT_RESOURCE_REQUEST');
export const FIND_NEXT_RESOURCE_SUCCESS = createActionName(reducerName, 'FIND_NEXT_RESOURCE_SUCCESS');
export const FIND_NEXT_RESOURCE_ERROR = createActionName(reducerName, 'FIND_NEXT_RESOURCE_ERROR');
export const FEEDBACK_RESOURCE_REQUEST = createActionName(reducerName, 'FEEDBACK_RESOURCE_REQUEST');
export const FEEDBACK_RESOURCE_SUCCESS = createActionName(reducerName, 'FEEDBACK_RESOURCE_SUCCESS');
export const FEEDBACK_RESOURCE_ERROR = createActionName(reducerName, 'FEEDBACK_RESOURCE_ERROR');
export const GET_FEEDBACK_ANSWERS_REQUEST = createActionName(reducerName, 'GET_FEEDBACK_ANSWERS_REQUEST');
export const GET_FEEDBACK_ANSWERS_SUCCESS = createActionName(reducerName, 'GET_FEEDBACK_ANSWERS_SUCCESS');
export const GET_FEEDBACK_ANSWERS_ERROR = createActionName(reducerName, 'GET_FEEDBACK_ANSWERS_ERROR');
export const GET_DOCUMENTS_REQUEST = createActionName(reducerName, 'GET_DOCUMENTS_REQUEST');
export const GET_DOCUMENTS_SUCCESS = createActionName(reducerName, 'GET_DOCUMENTS_SUCCESS');
export const GET_DOCUMENTS_ERROR = createActionName(reducerName, 'GET_DOCUMENTS_ERROR');
export const GET_FEEDBACK_IMAGES_REQUEST = createActionName(reducerName, 'GET_FEEDBACK_IMAGES_REQUEST');
export const GET_FEEDBACK_IMAGES_SUCCESS = createActionName(reducerName, 'GET_FEEDBACK_IMAGES_SUCCESS');
export const GET_FEEDBACK_IMAGES_ERROR = createActionName(reducerName, 'GET_FEEDBACK_IMAGES_ERROR');
export const GET_FEEDBACK_NO_SHOW_QUESTIONS_REQUEST = createActionName(reducerName, 'GET_FEEDBACK_NO_SHOW_QUESTIONS_REQUEST');
export const GET_FEEDBACK_NO_SHOW_QUESTIONS_SUCCESS = createActionName(reducerName, 'GET_FEEDBACK_NO_SHOW_QUESTIONS_SUCCESS');
export const GET_FEEDBACK_NO_SHOW_QUESTIONS_ERROR = createActionName(reducerName, 'GET_FEEDBACK_NO_SHOW_QUESTIONS_ERROR');
export const GET_FEEDBACK_OVERRUN_REQUEST = createActionName(reducerName, 'GET_FEEDBACK_OVERRUN_REQUEST');
export const GET_FEEDBACK_OVERRUN_SUCCESS = createActionName(reducerName, 'GET_FEEDBACK_OVERRUN_SUCCESS');
export const GET_FEEDBACK_OVERRUN_ERROR = createActionName(reducerName, 'GET_FEEDBACK_OVERRUN_ERROR');
export const GET_FEEDBACK_QUESTIONS_REQUEST = createActionName(reducerName, 'GET_FEEDBACK_QUESTIONS_REQUEST');
export const GET_FEEDBACK_QUESTIONS_SUCCESS = createActionName(reducerName, 'GET_FEEDBACK_QUESTIONS_SUCCESS');
export const GET_FEEDBACK_QUESTIONS_ERROR = createActionName(reducerName, 'GET_FEEDBACK_QUESTIONS_ERROR');
export const GET_INVOICEABLE_RESOURCES_REQUEST = createActionName(reducerName, 'GET_INVOICEABLE_RESOURCES_REQUEST');
export const GET_INVOICEABLE_RESOURCES_SUCCESS = createActionName(reducerName, 'GET_INVOICEABLE_RESOURCES_SUCCESS');
export const GET_INVOICEABLE_RESOURCES_ERROR = createActionName(reducerName, 'GET_INVOICEABLE_RESOURCES_ERROR');
export const GET_PAGINATED_RESOURCES_REQUEST = createActionName(reducerName, 'GET_PAGINATED_RESOURCES_REQUEST');
export const GET_PAGINATED_RESOURCES_SUCCESS = createActionName(reducerName, 'GET_PAGINATED_RESOURCES_SUCCESS');
export const GET_PAGINATED_RESOURCES_ERROR = createActionName(reducerName, 'GET_PAGINATED_RESOURCES_ERROR');
export const REJECT_CHANGE_RESOURCE_REQUEST = createActionName(reducerName, 'REJECT_CHANGE_RESOURCE_REQUEST');
export const REJECT_CHANGE_RESOURCE_SUCCESS = createActionName(reducerName, 'REJECT_CHANGE_RESOURCE_SUCCESS');
export const REJECT_CHANGE_RESOURCE_ERROR = createActionName(reducerName, 'REJECT_CHANGE_RESOURCE_ERROR');
export const SUBMIT_KEYS_NOT_RECEIVED_RESOURCE_REQUEST = createActionName(reducerName, 'SUBMIT_KEYS_NOT_RECEIVED_RESOURCE_REQUEST');
export const SUBMIT_KEYS_NOT_RECEIVED_RESOURCE_SUCCESS = createActionName(reducerName, 'SUBMIT_KEYS_NOT_RECEIVED_RESOURCE_SUCCESS');
export const SUBMIT_KEYS_NOT_RECEIVED_RESOURCE_ERROR = createActionName(reducerName, 'SUBMIT_KEYS_NOT_RECEIVED_RESOURCE_ERROR');
export const UPDATE_RESOURCE_REQUEST = createActionName(reducerName, 'UPDATE_RESOURCE_REQUEST');
export const UPDATE_RESOURCE_SUCCESS = createActionName(reducerName, 'UPDATE_RESOURCE_SUCCESS');
export const UPDATE_RESOURCE_ERROR = createActionName(reducerName, 'UPDATE_RESOURCE_ERROR');
export const UPLOAD_FEEDBACK_IMAGE_REQUEST = createActionName(reducerName, 'UPLOAD_FEEDBACK_IMAGE_REQUEST');
export const UPLOAD_FEEDBACK_IMAGE_SUCCESS = createActionName(reducerName, 'UPLOAD_FEEDBACK_IMAGE_SUCCESS');
export const UPLOAD_FEEDBACK_IMAGE_ERROR = createActionName(reducerName, 'UPLOAD_FEEDBACK_IMAGE_ERROR');
export const GET_DATE_FILTERED_RESOURCES_REQUEST = createActionName(reducerName, 'GET_DATE_FILTERED_RESOURCES_REQUEST');
export const GET_DATE_FILTERED_RESOURCES_SUCCESS = createActionName(reducerName, 'GET_DATE_FILTERED_RESOURCES_SUCCESS');
export const GET_DATE_FILTERED_RESOURCES_ERROR = createActionName(reducerName, 'GET_DATE_FILTERED_RESOURCES_ERROR');
export const REJECT_RESOURCE_REQUEST = createActionName(reducerName, 'REJECT_RESOURCE_REQUEST');
export const REJECT_RESOURCE_SUCCESS = createActionName(reducerName, 'REJECT_RESOURCE_SUCCESS');
export const REJECT_RESOURCE_ERROR = createActionName(reducerName, 'REJECT_RESOURCE_ERROR');
export const GET_FEEDBACK_KEY_HOLDING_REQUEST = createActionName(reducerName, 'GET_FEEDBACK_KEY_HOLDING_REQUEST');
export const GET_FEEDBACK_KEY_HOLDING_SUCCESS = createActionName(reducerName, 'GET_FEEDBACK_KEY_HOLDING_SUCCESS');
export const GET_FEEDBACK_KEY_HOLDING_ERROR = createActionName(reducerName, 'GET_FEEDBACK_KEY_HOLDING_ERROR');
export const SUBMIT_FEEDBACK_KEY_HOLDING_REQUEST = createActionName(reducerName, 'SUBMIT_FEEDBACK_KEY_HOLDING_REQUEST');
export const SUBMIT_FEEDBACK_KEY_HOLDING_SUCCESS = createActionName(reducerName, 'SUBMIT_FEEDBACK_KEY_HOLDING_SUCCESS');
export const SUBMIT_FEEDBACK_KEY_HOLDING_ERROR = createActionName(reducerName, 'SUBMIT_FEEDBACK_KEY_HOLDING_ERROR');

// actions
const actions = {
	ACCEPT_CHANGE_RESOURCE_REQUEST,
	ACCEPT_CHANGE_RESOURCE_SUCCESS,
	ACCEPT_CHANGE_RESOURCE_ERROR,
	CANCEL_RESOURCE_REQUEST,
	CANCEL_RESOURCE_SUCCESS,
	CANCEL_RESOURCE_ERROR,
	CHANGE_PAGE_RESOURCES,
	CLEAR_METADATA_RESOURCES,
	CLEAR_METADATA_RESOURCE_EDIT,
	DESTROY_RESOURCE_REQUEST,
	DESTROY_RESOURCE_SUCCESS,
	DESTROY_RESOURCE_ERROR,
	FIND_RESOURCE_REQUEST,
	FIND_RESOURCE_SUCCESS,
	FIND_RESOURCE_ERROR,
	FIND_NEXT_RESOURCE_REQUEST,
	FIND_NEXT_RESOURCE_SUCCESS,
	FIND_NEXT_RESOURCE_ERROR,
	FEEDBACK_RESOURCE_REQUEST,
	FEEDBACK_RESOURCE_SUCCESS,
	FEEDBACK_RESOURCE_ERROR,
	GET_FEEDBACK_ANSWERS_REQUEST,
  	GET_FEEDBACK_ANSWERS_SUCCESS,
	GET_FEEDBACK_ANSWERS_ERROR,
	GET_DOCUMENTS_REQUEST,
  	GET_DOCUMENTS_SUCCESS,
	GET_DOCUMENTS_ERROR,
	GET_FEEDBACK_IMAGES_REQUEST,
	GET_FEEDBACK_IMAGES_SUCCESS,
	GET_FEEDBACK_IMAGES_ERROR,
	GET_FEEDBACK_QUESTIONS_REQUEST,
	GET_FEEDBACK_NO_SHOW_QUESTIONS_REQUEST,
  	GET_FEEDBACK_NO_SHOW_QUESTIONS_SUCCESS,
  	GET_FEEDBACK_NO_SHOW_QUESTIONS_ERROR,
  	GET_FEEDBACK_QUESTIONS_SUCCESS,
  	GET_FEEDBACK_QUESTIONS_ERROR,
	GET_FEEDBACK_OVERRUN_REQUEST,
	GET_FEEDBACK_OVERRUN_SUCCESS,
	GET_FEEDBACK_OVERRUN_ERROR,
	GET_INVOICEABLE_RESOURCES_REQUEST,
	GET_INVOICEABLE_RESOURCES_SUCCESS,
	GET_INVOICEABLE_RESOURCES_ERROR,
	GET_PAGINATED_RESOURCES_REQUEST,
	GET_PAGINATED_RESOURCES_SUCCESS,
	GET_PAGINATED_RESOURCES_ERROR,
	REJECT_CHANGE_RESOURCE_REQUEST,
	REJECT_CHANGE_RESOURCE_SUCCESS,
	REJECT_CHANGE_RESOURCE_ERROR,
	SUBMIT_KEYS_NOT_RECEIVED_RESOURCE_REQUEST,
	SUBMIT_KEYS_NOT_RECEIVED_RESOURCE_SUCCESS,
	SUBMIT_KEYS_NOT_RECEIVED_RESOURCE_ERROR,
	UPDATE_RESOURCE_REQUEST,
	UPDATE_RESOURCE_SUCCESS,
	UPDATE_RESOURCE_ERROR,
	UPLOAD_FEEDBACK_IMAGE_REQUEST,
	UPLOAD_FEEDBACK_IMAGE_SUCCESS,
	UPLOAD_FEEDBACK_IMAGE_ERROR,
	GET_DATE_FILTERED_RESOURCES_REQUEST,
	GET_DATE_FILTERED_RESOURCES_SUCCESS,
	GET_DATE_FILTERED_RESOURCES_ERROR,
	REJECT_RESOURCE_REQUEST,
	REJECT_RESOURCE_SUCCESS,
	REJECT_RESOURCE_ERROR,
	GET_FEEDBACK_KEY_HOLDING_REQUEST,
	GET_FEEDBACK_KEY_HOLDING_SUCCESS,
	GET_FEEDBACK_KEY_HOLDING_ERROR,
	SUBMIT_FEEDBACK_KEY_HOLDING_REQUEST,
	SUBMIT_FEEDBACK_KEY_HOLDING_SUCCESS,
	SUBMIT_FEEDBACK_KEY_HOLDING_ERROR,
};

// action creators
export const acceptChangeResource = payload => ({
    type: actions.ACCEPT_CHANGE_RESOURCE_REQUEST,
    payload
});

export const cancelResource = payload => ({
    type: actions.CANCEL_RESOURCE_REQUEST,
    payload
});

export const changePageResources = payload => ({
    type: actions.CHANGE_PAGE_RESOURCES,
	payload
});

export const clearMetadataResourceEdit = payload => ({
    type: actions.CLEAR_METADATA_RESOURCE_EDIT,
});

export const clearMetadataResources = payload => ({
    type: actions.CLEAR_METADATA_RESOURCES,
    payload
});

export const destroyResource = payload => ({
    type: actions.DESTROY_RESOURCE_REQUEST,
    payload
});

export const feedbackResource = payload => ({
    type: actions.FEEDBACK_RESOURCE_REQUEST,
    payload
});

export const findResource = payload => ({
    type: actions.FIND_RESOURCE_REQUEST,
    payload
});

export const findNextResource = payload => ({
    type: actions.FIND_NEXT_RESOURCE_REQUEST,
    payload
});

export const getFeedbackAnswers = payload => ({
	type: actions.GET_FEEDBACK_ANSWERS_REQUEST,
    payload
});

export const getDocuments = payload => ({
	type: actions.GET_DOCUMENTS_REQUEST,
    payload
});

export const getFeedbackImages = payload => ({
	type: actions.GET_FEEDBACK_IMAGES_REQUEST,
    payload
});

export const getFeedbackNoShowQuestions = payload => ({
	type: actions.GET_FEEDBACK_NO_SHOW_QUESTIONS_REQUEST,
    payload
});

export const getFeedbackQuestions = payload => ({
	type: actions.GET_FEEDBACK_QUESTIONS_REQUEST,
    payload
});

export const getFeedbackOverrun = payload => ({
	type: actions.GET_FEEDBACK_OVERRUN_REQUEST,
    payload
});

export const getInvoiceableResources = payload => ({
    type: actions.GET_INVOICEABLE_RESOURCES_REQUEST,
    payload
});

export const getPaginatedResources = payload => ({
    type: actions.GET_PAGINATED_RESOURCES_REQUEST,
    payload
});

export const rejectChangeResource = payload => ({
    type: actions.REJECT_CHANGE_RESOURCE_REQUEST,
    payload
});

export const submitKeysNotReceived = payload => ({
    type: actions.SUBMIT_KEYS_NOT_RECEIVED_RESOURCE_REQUEST,
    payload
});

export const updateResource = payload => ({
    type: actions.UPDATE_RESOURCE_REQUEST,
    payload
});

export const uploadFeedbackImage = payload => ({
    type: actions.UPLOAD_FEEDBACK_IMAGE_REQUEST,
    payload
});

export const getDateFilterResources = payload => ({
    type: actions.GET_DATE_FILTERED_RESOURCES_REQUEST,
    payload
});

export const rejectResource = payload => ({
    type: actions.REJECT_RESOURCE_REQUEST,
    payload
});

export const getFeedbackKeyHolding = payload => ({
	type: actions.GET_FEEDBACK_KEY_HOLDING_REQUEST,
	payload
});

export const submitFeedbackKeyHolding = payload => ({
	type: actions.SUBMIT_FEEDBACK_KEY_HOLDING_REQUEST,
	payload
});

export default actions;
