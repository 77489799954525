export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('viewberDashboardState');
        // console.log('state loaded: ', serializedState);
        if(serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);

    } catch(err) {
        return undefined;
    }
}

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(sanitizeState(state));
        localStorage.setItem('viewberDashboardState', serializedState);
    } catch(err) {
        console.log(err);
    }
}

export const sanitizeState = (state) => {
    const { token } = state.auth;

    return {
        auth: { token },
        feedbackFormValues: state.feedbackFormValues
    }
};
