import has from "lodash/has";
import isNil from "lodash/isNil";

/**
 * Determine if a viewing occurs on a date and time. If the viewing
 * is not defined or does not have a main_viewing_length attached
 * then we default to true as more records occur on date and time
 * than date only so we will assume it is date and time.
 *
 * @param viewing
 * @returns {boolean|boolean}
 */
export const viewingOccursOnDateAndTime = viewing => {
    return isNil(viewing)
        || !has(viewing, 'main_viewing_length')
        || viewing.main_viewing_length === null
        || viewing.main_viewing_length.occurs_on_date_only === false;
}

/**
 * Determine if a viewing occurs on date only.
 *
 * @param viewing
 * @returns {boolean|boolean}
 */
export const viewingOccursOnDateOnly = viewing => {
    return viewingOccursOnDateAndTime(viewing) === false;
}