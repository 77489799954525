import axios from './axios'
import { get } from 'lodash'

export const apiRequest = (url, opts) => new Promise((resolve, reject) => {
    const method = get(opts, 'method', 'GET')
    const params = get(opts, 'params', undefined)
    const data = get(opts, 'data', undefined)
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }

    // Add the authorization token if given.
    if (opts.token) {
        headers['Authorization'] = `Bearer ${opts.token}`
    }
    
    axios({
        method,
        url,
        data,
        params,
        headers
    })
    .then(resolve)
    .catch(reject);
});

export const apiRequestWithBaseUrl = (uri, opts) => {
    if (uri.charAt(0) !== '/') {
        // Conveniently add a '/' to the start of the
        // URI if the user has not already added it.
        uri = '/' + uri;
    }

    const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1${uri}`;

    return apiRequest(url, opts);
};

export default apiRequest;