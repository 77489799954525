import ListResourceContainer from './ListResourceContainer';
import ListResource from './ListResource';
import withListResource from './withListResource';

// Please note that ListResource and ListResourceContainer are 2 different components,
// You'd most probably always use the default export (ListResourceContainer),
// As that deals with wrapping everything with PageTitle and ApiErrorsCard component.
// In case you need just the ListGroup/DataTable part of it you can use the following statement:
// import { ListResource } from './components/ListResource';
// As "import ListResource from './components/ListResource';" will import the Container
// This is because imports from default exports, can be named as you like them
// e.g. you could have import Bruh from './components/ListResource';
// Which will still result in ListResourceContainer being used.

export {
    ListResource,
    withListResource,
};

export default ListResourceContainer;
