import get from 'lodash/get';
import isNil from 'lodash/isNil';
import isError from 'lodash/isError';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import { isAxiosError } from '../api/axios';

/**
 * Normalizes the API errors into an array format, containing all the messages.
 * Returns an empty array if no errors returned by the API.
 */
export const getApiErrorMessages = (error, skipTokenNotProvidedError = true) => {
    let errors = [];
    let ignoreObjectKeys = skipTokenNotProvidedError
        ? ['token_not_provided']
        : [];

    if (isNil(error)) {
        return errors;
    }

    if (isAxiosError(error)) {
        let responseMessage = get(error, 'response.data.message');
        let responseErrorStrings = extractStrings(get(error, 'response.data.error'), {ignoreObjectKeys});
        let responseErrorsStrings = extractStrings(get(error, 'response.data.errors'), {ignoreObjectKeys});

        if (!isNil(get(error, 'response.data.message'))) {
            errors.push(get(error, 'response.data.message'));
        }

        // Add the error only if it does not already exist.
        if (errors.indexOf(responseMessage) === -1) {
            errors.push(responseMessage);
        }

        responseErrorStrings.forEach(s => {
            // Add the errors only if they do not already exist.
            if (errors.indexOf(s) === -1) {
                errors.push(s);
            }
        });

        responseErrorsStrings.forEach(s => {
            // Add the errors only if they do not already exist.
            if (errors.indexOf(s) === -1) {
                errors.push(s);
            }
        });
    } else if (isError(error)) {
        errors.push(error.message);
    } else {
        extractStrings(error, {ignoreObjectKeys}).forEach(s => errors.push(s));
    }

    return errors;
};

const extractStrings = (object, {currentNestingLevel = 0, maxNesting = 5, ignoreObjectKeys = []}) => {
    // Initialize variables.
    let strings = [];

    // Make sure the nesting values are appropriate values and if not we will
    // change them to appropriate values.
    currentNestingLevel = returnIfIsWithinRange(currentNestingLevel, [1, 6], 0);
    maxNesting = returnIfIsWithinRange(maxNesting, [1, 5], 5);

    // Return the strings early if the currentNestingLevel is greater than maxNesting.
    if (currentNestingLevel > maxNesting) {
        return strings;
    }

    // Recursively extract strings.
    if (isString(object)) {
        strings.push(object);
    } else if (isArray(object)) {
        object.forEach(arrayItem => {
            extractStrings(arrayItem, {
                maxNesting,
                currentNestingLevel: currentNestingLevel + 1,
                ignoreObjectKeys
            }).forEach(s => strings.push(s));
        });
    } else if (isObject(object)) {
        Object.keys(object).forEach(key => {
            if (ignoreObjectKeys && ignoreObjectKeys.indexOf(key) === -1) {
                extractStrings(object[key], {
                    maxNesting,
                    currentNestingLevel: currentNestingLevel + 1,
                    ignoreObjectKeys
                }).forEach(s => strings.push(s));
            }
        });
    } else if (isError(object)) {
        strings.push(object.message);
    }

    // Return the strings.
    return strings;
}

/**
 * Build an array containing the numbers within
 * the supplied range.
 * 
 * @param {Number} n 
 * @param {Number} m 
 * @returns {Array}
 */
const buildRange = (n,m) => {
    let r = [];
    for(let i=n; i<=m; i++) {
        r.push(i);
    }
    return r;
}

/**
 * Strictly test if an item is in an array.
 * 
 * @param {*} item 
 * @param {Array} array 
 * @returns {Boolean}
 */
const inArrayStrict = (item, array) => {
    for(let i=0,il=array.length; i<il; i++) {
        if (item === array[i]) {
            return true;
        }
    }
    return false;
}

/**
 * Check to see if a value is within a range of numbers,
 * if so, return the value, otherwise, return a default value.
 * 
 * @param {Number} value The value to test.
 * @param {Array} range An array tuple [rangeStart:number, rangeEnd:number]
 * @param {Number} defaultValue A value to return if the testing value is not within the range.
 * @returns {Number}
 */
const returnIfIsWithinRange = (value, range, defaultValue) => {
    if (inArrayStrict(value, buildRange(range[0], range[1]))) {
        return value;
    }
    return defaultValue;
}

export default getApiErrorMessages;
