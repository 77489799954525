import actions from './actions'

const initial_state = { 
    error: null,
    fetching_resources: false,
    resource: null,
};

const reducer = (state = initial_state, action) => {
    switch(action.type) {
        case actions.GET_BY_CATEGORY_ID_REQUEST: {
            const { data } = action.payload;
            return {
                ...state,
                error: null,
                fetching_resources: true,
                resource: null,
            };
        }
        case actions.GET_BY_CATEGORY_ID_REQUEST_SUCCESS: {
            const { resource } = action.payload;
            return {
                ...state,
                resource: resource,
                error: null,
                fetching_resources: false,
            };
        }
        case actions.GET_VIEWBERS_MANUAL_REQUEST_ERROR:
            return {
                ...state,
                error: action.error,
                fetching_resources: false,
                resource: null,
            };
            return {
                ...state,
                error: action.error,
                fetching_resources: false,
                viewbers_manual: null,
            };
        default:
            return state;
    }
};

export default reducer;
