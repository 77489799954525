import { all, takeEvery, fork } from 'redux-saga/effects';
import actions from './actions';
import { sagaApiRequest } from '../helpers';
import * as appointmentsAPI from '../../api/appointments';

export function* acceptChangeResourceRequest() {
    yield takeEvery(actions.ACCEPT_CHANGE_RESOURCE_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: appointmentsAPI.acceptChange,
            args: payload.data,
            onSuccess: actions.ACCEPT_CHANGE_RESOURCE_SUCCESS,
            onError: actions.ACCEPT_CHANGE_RESOURCE_ERROR
        });
    });
}

export function* cancelResourceRequest() {
    yield takeEvery(actions.CANCEL_RESOURCE_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: appointmentsAPI.cancel,
            args: payload.data,
            onSuccess: actions.CANCEL_RESOURCE_SUCCESS,
            onError: actions.CANCEL_RESOURCE_ERROR
        });
    });
}

export function* destroyResourceRequest() {
    yield takeEvery(actions.DESTROY_RESOURCE_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: appointmentsAPI.destroy,
            args: payload.data,
            onSuccess: actions.DESTROY_RESOURCE_SUCCESS,
            onError: actions.DESTROY_RESOURCE_ERROR
        });
    });
}

export function* feedbackResourceRequest() {
    yield takeEvery(actions.FEEDBACK_RESOURCE_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: appointmentsAPI.feedback,
            args: payload.data,
            onSuccess: actions.FEEDBACK_RESOURCE_SUCCESS,
            onError: actions.FEEDBACK_RESOURCE_ERROR
        });
    });
}

export function* findNextResourceRequest() {
    yield takeEvery(actions.FIND_NEXT_RESOURCE_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: appointmentsAPI.findNext,
            args: payload.data,
            onSuccess: actions.FIND_NEXT_RESOURCE_SUCCESS,
            onError: actions.FIND_NEXT_RESOURCE_ERROR
        });
    });
}

export function* findResourceRequest() {
    yield takeEvery(actions.FIND_RESOURCE_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: appointmentsAPI.find,
            args: payload.data,
            onSuccess: actions.FIND_RESOURCE_SUCCESS,
            onError: actions.FIND_RESOURCE_ERROR
        });
    });
}

export function* getFeedbackAnswersRequest() {
    yield takeEvery(actions.GET_FEEDBACK_ANSWERS_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: appointmentsAPI.feedbackAnswers,
            args: payload.data,
            onSuccess: actions.GET_FEEDBACK_ANSWERS_SUCCESS,
            onError: actions.GET_FEEDBACK_ANSWERS_ERROR
        });
    });
}

export function* getFeedbackImagesRequest() {
    yield takeEvery(actions.GET_FEEDBACK_IMAGES_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: appointmentsAPI.feedbackImages,
            args: payload.data,
            onSuccess: actions.GET_FEEDBACK_IMAGES_SUCCESS,
            onError: actions.GET_FEEDBACK_IMAGES_ERROR
        });
    });
}

export function* getFeedbackNoShowQuestionsRequest() {
    yield takeEvery(actions.GET_FEEDBACK_NO_SHOW_QUESTIONS_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: appointmentsAPI.feedbackNoShowQuestions,
            args: payload,
            onSuccess: actions.GET_FEEDBACK_NO_SHOW_QUESTIONS_SUCCESS,
            onError: actions.GET_FEEDBACK_NO_SHOW_QUESTIONS_ERROR
        });
    });
}

export function* getFeedbackQuestionsRequest() {
    yield takeEvery(actions.GET_FEEDBACK_QUESTIONS_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: appointmentsAPI.feedbackQuestions,
            args: payload.data,
            onSuccess: actions.GET_FEEDBACK_QUESTIONS_SUCCESS,
            onError: actions.GET_FEEDBACK_QUESTIONS_ERROR
        });
    });
}

export function* getFeedbackOverrunRequest() {
    yield takeEvery(actions.GET_FEEDBACK_OVERRUN_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: appointmentsAPI.feedbackOverrun,
            args: payload.data,
            onSuccess: actions.GET_FEEDBACK_OVERRUN_SUCCESS,
            onError: actions.GET_FEEDBACK_OVERRUN_ERROR
        });
    });
}

export function* getInvoiceableResourcesRequest() {
    yield takeEvery(actions.GET_INVOICEABLE_RESOURCES_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: appointmentsAPI.invoiceable,
            args: payload.data,
            onSuccess: actions.GET_INVOICEABLE_RESOURCES_SUCCESS,
            onError: actions.GET_INVOICEABLE_RESOURCES_ERROR
        });
    });
}

export function* getPaginatedResourcesRequest() {
    yield takeEvery(actions.GET_PAGINATED_RESOURCES_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: appointmentsAPI.paginate,
            args: payload.data,
            onSuccess: actions.GET_PAGINATED_RESOURCES_SUCCESS,
            onError: actions.GET_PAGINATED_RESOURCES_ERROR
        });
    });
}

export function* rejectChangeResourceRequest() {
    yield takeEvery(actions.REJECT_CHANGE_RESOURCE_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: appointmentsAPI.rejectChange,
            args: payload.data,
            onSuccess: actions.REJECT_CHANGE_RESOURCE_SUCCESS,
            onError: actions.REJECT_CHANGE_RESOURCE_ERROR
        });
    });
}

export function* submitKeysNotReceivedRequest() {
    yield takeEvery(actions.SUBMIT_KEYS_NOT_RECEIVED_RESOURCE_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: appointmentsAPI.keysNotReceived,
            args: payload.data,
            onSuccess: actions.SUBMIT_KEYS_NOT_RECEIVED_RESOURCE_SUCCESS,
            onError: actions.SUBMIT_KEYS_NOT_RECEIVED_RESOURCE_ERROR
        });
    });
}

export function* updateResourceRequest() {
    yield takeEvery(actions.UPDATE_RESOURCE_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: appointmentsAPI.update,
            args: payload.data,
            onSuccess: actions.UPDATE_RESOURCE_SUCCESS,
            onError: actions.UPDATE_RESOURCE_ERROR
        });
    });
}

export function* uploadFeedbackImageRequest() {
    yield takeEvery(actions.UPLOAD_FEEDBACK_IMAGE_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: appointmentsAPI.uploadFeedbackImage,
            args: payload.data,
            onSuccess: actions.UPLOAD_FEEDBACK_IMAGE_SUCCESS,
            onError: actions.UPLOAD_FEEDBACK_IMAGE_ERROR
        });
    });
}

export function* getDateFilterResourcesRequest() {
    yield takeEvery(actions.GET_DATE_FILTERED_RESOURCES_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: appointmentsAPI.dateFilter,
            args: payload.data,
            onSuccess: actions.GET_DATE_FILTERED_RESOURCES_SUCCESS,
            onError: actions.GET_DATE_FILTERED_RESOURCES_ERROR
        });
    });
}

export function* getDocumentsRequest() {
    yield takeEvery(actions.GET_DOCUMENTS_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: appointmentsAPI.documents,
            args: payload.data,
            onSuccess: actions.GET_DOCUMENTS_SUCCESS,
            onError: actions.GET_DOCUMENTS_ERROR
        });
    });
}

export function* rejectResourceRequest() {
    yield takeEvery(actions.REJECT_RESOURCE_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: appointmentsAPI.keyholderRejectChange,
            args: payload.data,
            onSuccess: actions.REJECT_RESOURCE_SUCCESS,
            onError: actions.REJECT_RESOURCE_ERROR
        });
    });
}

export function* getFeedbackKeyHoldingRequest() {
    yield takeEvery(actions.GET_FEEDBACK_KEY_HOLDING_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: appointmentsAPI.feedbackKeyHolding,
            args: payload,
            onSuccess: actions.GET_FEEDBACK_KEY_HOLDING_SUCCESS,
            onError: actions.GET_FEEDBACK_KEY_HOLDING_ERROR
        });
    });
}

export function* submitFeedbackKeyHoldingRequest() {
    yield takeEvery(actions.SUBMIT_FEEDBACK_KEY_HOLDING_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: appointmentsAPI.feedbackKeyHoldingSubmit,
            args: payload,
            onSuccess: actions.SUBMIT_FEEDBACK_KEY_HOLDING_SUCCESS,
            onError: actions.SUBMIT_FEEDBACK_KEY_HOLDING_ERROR
        });
    });
}

export default function* rootSaga() {
    yield all([
        fork(acceptChangeResourceRequest),
        fork(cancelResourceRequest),
        fork(destroyResourceRequest),
        fork(feedbackResourceRequest),
        fork(findNextResourceRequest),
        fork(findResourceRequest),
        fork(getDocumentsRequest),
        fork(getFeedbackAnswersRequest),
        fork(getFeedbackImagesRequest),
        fork(getFeedbackNoShowQuestionsRequest),
        fork(getFeedbackQuestionsRequest),
        fork(getFeedbackOverrunRequest),
        fork(getInvoiceableResourcesRequest),
        fork(getPaginatedResourcesRequest),
        fork(rejectChangeResourceRequest),
        fork(submitKeysNotReceivedRequest),
        fork(updateResourceRequest),
        fork(uploadFeedbackImageRequest),
        fork(getDateFilterResourcesRequest),
        fork(rejectResourceRequest),
        fork(getFeedbackKeyHoldingRequest),
        fork(submitFeedbackKeyHoldingRequest),
    ]);
}
