import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import isFunction from "lodash/isFunction";

const PlaceholderShimmerLoader = ({ columns }) => {
    return (
        <Row className="placeholder-shimmer-loader-row">
            {
                columns.map((column, colIdx) => {
                    if(column.dataField === 'id') {
                        return null;
                    }

                    const columnClassName = isFunction(column.className)
                        ? column.className(undefined)
                        : column.className;

                    const style = column.style
                        ? column.style
                        : {height: '14px'};

                    return (
                        <Col key={colIdx} className={columnClassName}>
                            <div
                                className="animated-background"
                                style={style}
                            >
                            </div>
                        </Col>
                    );
                })
            }
        </Row>
    );
};

PlaceholderShimmerLoader.propTypes = {
    columns: PropTypes.array,
};

export default PlaceholderShimmerLoader;
