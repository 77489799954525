import { all, takeEvery, fork } from 'redux-saga/effects';
import actions from './actions';
import { sagaApiRequest } from '../helpers';
import * as viewberBillingTypesAPI from '../../api/viewberBillingTypes';

export function* findResourceRequest() {
    yield takeEvery(actions.FIND_RESOURCE_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: viewberBillingTypesAPI.find,
            args: payload.data,
            onSuccess: actions.FIND_RESOURCE_SUCCESS,
            onError: actions.FIND_RESOURCE_ERROR
        });
    });
}

export function* getAllResourcesRequest() {
    yield takeEvery(actions.GET_ALL_RESOURCES_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: viewberBillingTypesAPI.all,
            args: payload.data,
            onSuccess: actions.GET_ALL_RESOURCES_SUCCESS,
            onError: actions.GET_ALL_RESOURCES_ERROR
        });
    });
}

export function* getPaginatedResourcesRequest() {
    yield takeEvery(actions.GET_PAGINATED_RESOURCES_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: viewberBillingTypesAPI.paginate,
            args: payload.data,
            onSuccess: actions.GET_PAGINATED_RESOURCES_SUCCESS,
            onError: actions.GET_PAGINATED_RESOURCES_ERROR
        });
    });
}

export default function* rootSaga() {
    yield all([
        fork(findResourceRequest),
        fork(getAllResourcesRequest),
        fork(getPaginatedResourcesRequest),
    ]);
}
