import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row, Button } from 'reactstrap';
import withEditProfile from './withEditProfile';
import ApiErrorCard from '../../components/Cards/ApiErrorCard';
import PageTitle from '../../components/PageTitle';
import SpinnerLoader from '../../components/SpinnerLoader';
import { keyholding as getKeyholdingProperties } from '../../api/properties';
import { ListResource } from '../../components/Resources/ListResource';
import { columns, pageSize } from '../../redux/keys/schema'

const KeysHeld = ({ token }) => {   
    const [keys, setKeys] = useState({
        data: undefined,
        loading: true,
        error: undefined
    });

    const loadKeys = () => {
        if (!keys.data && keys.loading) {
            setKeys(prev => ({
                ...prev,
                loading: true,
                error: undefined
            }));
            getKeyholdingProperties({ token }).then(r => {
                setKeys({
                    data: r.data.resource,
                    loading: false,
                    error: undefined
                });
            }).catch(err => {
                setKeys(prev => ({
                    ...prev,
                    loading: false,
                    error: err
                }));
            })
        }
    }

    useEffect(() => {
        loadKeys();
    }, []);

    const profileTitle = keys.loading
        ? 'Loading...'
        : 'Keys Held';

    let content;

    if (keys.loading) {
        content = <SpinnerLoader />;
    } else if (keys.error) {
        content = (
            <div className='text-center'>
                <div className='mb-3'><i className="fa fa-2x fa-exclamation-triangle"></i></div>
                <p className='mb-3'> Failed to load the properties.</p>
                <Button color="secondary" size="sm" onClick={() => loadKeys}>Retry</Button>
            </div>
        )
    } else if (keys.data !== undefined) {
        content = <ListResource
            type='list'
            data={keys.data}
            columns={columns}
            pageSize={pageSize}
            paginationDisabled
        />;
    } else {
        content = (
            <div className='text-center'>
                <div className='mb-3'><i className="fa fa-2x fa-exclamation-triangle"></i></div>
                <p className='mb-3'> No data.</p>
            </div>
        )
    }

    return (
        <div className="animated fadeIn">
            <Row>
                <Col className="col-md-12">
                    <ApiErrorCard />
                </Col>
            </Row>

            <>
                <PageTitle text={profileTitle} />
                <Row>
                    <Col className="col-md-12">
                        <Card className="card-accent-warning">
                            <CardBody>
                                {content}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>


        </div>
    );
};



export default withEditProfile(KeysHeld);
