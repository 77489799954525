import actions from './actions'

const initialState = [
    {
        number_of_viewers: undefined,
        feedback_answers: [],
        travel_answers: {
            travel_methods: []
        },
        property_answers: [],
        feedback_overrun: {
            duration: undefined,
            contact_person: undefined,
            evidence_photo: undefined,
        },
        keycode: '',
        gdpr: 0,
        notes: '',
        jotForm: 0
    }
]

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_FEEDBACK_FORM_VALUES:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}

export default reducer