import * as chatsAPI from '../index';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import {useQuery, useQueryClient} from '@tanstack/react-query'

export const useUnreadMessagesCount = (
    token,
    options
) => {
  const queryClient = useQueryClient();

  const {
    data,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery(
    ['fetch_unread_counts'],
    () => {
      return new Promise((resolve, reject) => {
        chatsAPI.unreadCounts({
          token,
          params: {
            expand: get(options, 'expand')
          }
        })
        .then(res => resolve(res.data.count))
        .catch(reject);
      })
    }
  );

  return {
    data,
    isLoading,
    isError,
    error,
    refetch
  }
}