import createActionName from '../createActionName';

export const SET_FEEDBACK_FORM_VALUES = createActionName('feedbackForm', 'SET_FEEDBACK_FORM_VALUES');

const actions = {
    SET_FEEDBACK_FORM_VALUES,
}

export const setFeedbackFormValues = payload => ({
    type: actions.SET_FEEDBACK_FORM_VALUES,
    payload
})

export default actions