import React from 'react';
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
} from 'reactstrap';

const MaintenanceCard = ({ maintenancePeriod }) => {
    return (
        <div className="app flex-row align-items-center">
            <Container>
                <Row className="justify-content-center">
                    <Col className="col-md-6">
                        <Card className="p-2 card-accent-danger">
                            <CardBody>
                                <h1>
                                    <i className="fa fa-fw fa-wrench"></i>
                                    {' '}
                                    Under Maintenance
                                </h1>
                                <p>
                                    <strong>Please note:</strong>
                                    {' '}
                                    the system is currently unavailable due to planned system maintenance.
                                    {' '}
                                    Please try again at {maintenancePeriod.endFormattedTime}.
                                    {' '}
                                    Apologies for the inconvenience.
                                </p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default MaintenanceCard;
