import {Link} from "react-router-dom";
import React from "react";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";

const MissingHmrcDetails = ({ missingDetails }: {missingDetails: []}) => {
    return (
        <Row>
            <Col className="col-md-12">
                <Card className="card-accent-danger">
                    <CardHeader className="bg-danger">
                        <i className="fa fa-fw fa-exclamation-triangle"></i>
                        Missing Details
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col className="col-12">
                                <p className="mb-0">
                                    In line with HMRC regulations for digital platforms, please complete the information
                                    requested <Link to={'/me/billing'}>here</Link>: {missingDetails.join(", ")}
                                </p>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default MissingHmrcDetails