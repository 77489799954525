import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as userCalendarApi from '../../api/userCalendar';

export const checkItRequiresCalendarUpdate = createAsyncThunk(
    'userCalendar/checkItRequiresCalendarUpdate',
    async (arg, {getState, requestId}) => {
        const { loadingAll, loadingAllCurrentRequestId } = getState().userCalendar

        if (loadingAll !== 'pending' || requestId !== loadingAllCurrentRequestId) {
            return
        }

        const response = await userCalendarApi.checkItRequiresCalendarUpdate(getState().auth.token)
        return response.data
    }
)

export const confirmCalendarUpdated = createAsyncThunk(
    'userCalendar/confirmCalendarUpdated',
    async (arg, {getState, requestId}) => {
        const { confirmingCalendarUpdate, confirmingCalendarUpdateCurrentRequestId } = getState().userCalendar
        if (confirmingCalendarUpdate !== 'pending' || requestId !== confirmingCalendarUpdateCurrentRequestId) {
            return
        }
        const response = await userCalendarApi.confirmCalendarUpdated(getState().auth.token)
        return response.data
    }
)

const calendarSlice = createSlice({
    name: 'userCalendar',
    initialState: {
        /**
         * Loading all.
         */
        loadingAll: 'idle',
        loadingAllCurrentRequestId: undefined,
        loadingAllError: null,

        confirmingCalendarUpdate: 'idle',
        confirmingCalendarUpdateCurrentRequestId: undefined,
        confirmingCalendarUpdateCurrentError: null,

        hasCheckedItRequiresCalendarUpdate: false,

        requiresCalendarUpdate: false,
        loading: {},
    },
    reducers: {

    },
    extraReducers: {
        [checkItRequiresCalendarUpdate.pending]: (state, action) => {
            if (state.loadingAll === 'idle') {
                state.loadingAll = 'pending'
                state.loadingAllCurrentRequestId = action.meta.requestId
            }
        },
        [checkItRequiresCalendarUpdate.fulfilled]: (state, action) => {
            const { requestId } = action.meta
            if (state.loadingAll === 'pending' && state.loadingAllCurrentRequestId === requestId) {
                state.loadingAll = 'idle'

                state.requiresCalendarUpdate = action.payload.requiresCalendarUpdate
                state.loadingAllCurrentRequestId = undefined
                state.hasCheckedItRequiresCalendarUpdate = true
            }
        },
        [checkItRequiresCalendarUpdate.rejected]: (state, action) => {
            const { requestId } = action.meta
            if (state.loadingAll === 'pending' && state.loadingAllCurrentRequestId === requestId) {
                state.loadingAll = 'idle'
                state.loadingAllError = action.error
                state.loadingAllCurrentRequestId = undefined
            }
        },
        [confirmCalendarUpdated.pending]: (state, action) => {
            if (state.confirmingCalendarUpdate === 'idle') {
                state.confirmingCalendarUpdate = 'pending'
                state.confirmingCalendarUpdateCurrentRequestId = action.meta.requestId
            }
        },
        [confirmCalendarUpdated.fulfilled]: (state, action) => {
            const { requestId } = action.meta
            if (state.confirmingCalendarUpdate === 'pending' && state.confirmingCalendarUpdateCurrentRequestId === requestId) {
                state.confirmingCalendarUpdate = 'idle'
                state.requiresCalendarUpdate = false
                state.confirmingCalendarUpdateCurrentRequestId = undefined
                state.hasCheckedItRequiresCalendarUpdate = true
            }
        },
        [confirmCalendarUpdated.rejected]: (state, action) => {
            const { requestId } = action.meta
            if (state.confirmingCalendarUpdate === 'pending' && state.confirmingCalendarUpdateCurrentRequestId === requestId) {
                state.confirmingCalendarUpdate = 'idle'
                state.confirmingCalendarUpdateCurrentError = action.error
                state.confirmingCalendarUpdateCurrentRequestId = undefined
            }
        }
    }
})

const { reducer } = calendarSlice

export default reducer