import createActionName from '../createActionName';

export const reducerName = 'Academy';

export const FIND_RESOURCE_REQUEST = createActionName(reducerName, 'FIND_RESOURCE_REQUEST');
export const FIND_RESOURCE_SUCCESS = createActionName(reducerName, 'FIND_RESOURCE_SUCCESS');
export const FIND_RESOURCE_ERROR = createActionName(reducerName, 'FIND_RESOURCE_ERROR');

const actions = {
    FIND_RESOURCE_REQUEST,
    FIND_RESOURCE_SUCCESS,
    FIND_RESOURCE_ERROR
};

export const findResource = payload => {
    return {
        type: actions.FIND_RESOURCE_REQUEST,
        payload
    }
}

export default actions;