import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import isArray from 'lodash/isArray';
import * as notificationsApi from '../../api/notifications';

export const getNotifications = createAsyncThunk(
    'notifications/getNotifications',
    async (page, {getState, requestId}) => {
        const { pages } = getState().notifications
      
        if (
            !pages.hasOwnProperty(page)
            || (
                pages.hasOwnProperty(page)
                && (
                    pages[page].loading !== 'pending'
                    || pages[page].currentRequestId !== requestId
                )
            )
        ) {
            return
        }
    
        const response = await notificationsApi.getNotifications(
            getState().auth.token,
            {params: { page }}
        );
    
        return response.data
    }
)

export const readNotification = createAsyncThunk(
    'notifications/readNotification',
    async (notificationId, {getState, requestId}) => {
        // Get the notification ids. The ids can be a single
        // id or an array of ids.
        let notificationIds = isArray(notificationId)
            ? notificationId.concat()
            : [notificationId]
      
        // Read the notification(s).
        const response = await notificationsApi.readNotification(
            getState().auth.token,
            notificationIds
        );
    
        // Return the result.
        return response.data.data
    }
)

export const markAllRead = createAsyncThunk(
    'notifications/mark-all-read',
    async (arg = null, {getState}) => {
        // Read the notification(s).
        const response = await notificationsApi.markAllRead(
            getState().auth.token,
        );
        return 
    }
)

export const getLatestNotifications = createAsyncThunk(
    'notifications/getLatestNotifications',
    async (limit = 3, {getState, requestId}) => {
        const { latestNotifications } = getState().notifications
      
        if (latestNotifications.loading !== 'pending' || latestNotifications.currentRequestId !== requestId) {
            return
        }
    
        const response = await notificationsApi.getLatestNotifications(
            getState().auth.token,
            limit
        )
    
        return response.data
    }
)

export const getLatestUnreadNotifications = createAsyncThunk(
    'notifications/getLatestNotifications',
    async (limit = 3, {getState, requestId}) => {
        const { latestUnreadNotifications } = getState().notifications
      
        if (latestUnreadNotifications.loading !== 'pending' || latestUnreadNotifications.currentRequestId !== requestId) {
            return
        }
    
        const response = await notificationsApi.getNotifications(
            getState().auth.token,
            {params: { limit: 3, unread: 1}}
        )
    
        return response.data
    }
)

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: {
        pages: {},

        readNotifications: {},

        latestNotifications: {
            data: [],
            loading: 'idle',
            currentRequestId: undefined,
            error: null
        },

        latestUnreadNotifications: {
            data: [],
            totalUnreadNotifications: undefined,
            loading: 'idle',
            currentRequestId: undefined,
            error: null
        },
    },
    reducers: {},
    extraReducers: {
        [getNotifications.pending]: (state, action) => {
            const { arg, requestId } = action.meta
            if (!state.pages.hasOwnProperty(arg)) {
                state.pages[arg] = {
                    data: {
                        data: [],
                        meta: {},
                        links: {}
                    },
                    loading: 'pending',
                    currentRequestId: requestId,
                    error: null
                }
            } else if (state.pages[arg].loading === 'idle') {
                state.pages[arg].loading = 'pending'
                state.pages[arg].currentRequestId = requestId
                state.pages[arg].error = null
            }
        },
        [getNotifications.fulfilled]: (state, action) => {
            const { arg, requestId } = action.meta
            if (
                state.pages.hasOwnProperty(arg)
                && state.pages[arg].loading === 'pending'
                && state.pages[arg].currentRequestId === requestId
            ) {
                state.pages[arg] = {
                    data: action.payload,
                    loading: 'idle',
                    currentRequestId: undefined,
                    error: null
                }
            }
        },
        [getNotifications.rejected]: (state, action) => {
            const { arg, requestId } = action.meta
            if (
                state.pages.hasOwnProperty(arg)
                && state.pages[arg].loading === 'pending'
                && state.pages[arg].currentRequestId === requestId
            ) {
                state.pages[arg].loading = 'idle'
                state.pages[arg].error = action.error
                state.pages[arg].currentRequestId = undefined
            }
        },

        [readNotification.pending]: (state, action) => {
            const { arg, requestId } = action.meta

            // Get the notification ids, the arg can be an id or
            // an array of ids.
            let notificationIds = isArray(arg) ? arg.concat() : [arg]

            // Update the read notifications state for each notification id.
            notificationIds.forEach(id => {
                if (!state.readNotifications.hasOwnProperty(id)) {
                    state.readNotifications[id] = {
                        data: [],
                        loading: 'pending',
                        currentRequestId: requestId,
                        error: null
                    }
                } else if (state.readNotifications[id].loading === 'idle') {
                    state.readNotifications[id].loading = 'pending'
                    state.readNotifications[id].currentRequestId = requestId
                    state.readNotifications[id].error = null
                }
            })
        },
        [readNotification.fulfilled]: (state, action) => {
            const { arg, requestId } = action.meta

            // Get the response notifications.
            let responseNotifications = isArray(action.payload)
                ? action.payload.concat()
                : [action.payload];

            // Update the read notifications state for each notification.
            responseNotifications.forEach(notification => {
                if (
                    state.readNotifications.hasOwnProperty(notification.id)
                    && state.readNotifications[notification.id].loading === 'pending'
                    && state.readNotifications[notification.id].currentRequestId === requestId
                ) {
                    state.readNotifications[notification.id] = {
                        data: notification,
                        loading: 'idle',
                        currentRequestId: undefined,
                        error: null
                    }
                }
            })
        },
        [readNotification.rejected]: (state, action) => {
            const { arg, requestId } = action.meta

            // Get the notification ids, the arg can be an id or
            // an array of ids.
            let notificationIds = isArray(arg) ? arg.concat() : [arg]

            // Update the read notifications state for each notification id.
            notificationIds.forEach(id => {
                if (
                    state.readNotifications.hasOwnProperty(id)
                    && state.readNotifications[id].loading === 'pending'
                    && state.readNotifications[id].currentRequestId === requestId
                ) {
                    state.readNotifications[id].loading = 'idle'
                    state.readNotifications[id].error = action.error
                    state.readNotifications[id].currentRequestId = undefined
                }
            })
        },

        [getLatestNotifications.pending]: (state, action) => {
            const { latestNotifications } = state
            const { requestId } = action.meta
            if (latestNotifications.loading === 'idle') {
                state.latestNotifications.loading = 'pending'
                state.latestNotifications.currentRequestId = requestId
                state.latestNotifications.error = null
            }
        },
        [getLatestNotifications.fulfilled]: (state, action) => {
            const { latestNotifications } = state
            const { requestId } = action.meta
            if (
                latestNotifications.loading === 'pending'
                && latestNotifications.currentRequestId === requestId
            ) {
                state.latestNotifications = {
                    data: action.payload.data,
                    loading: 'idle',
                    currentRequestId: undefined,
                    error: null
                }
            }
        },
        [getLatestNotifications.rejected]: (state, action) => {
            const { latestNotifications } = state
            const { requestId } = action.meta
            if (
                latestNotifications.loading === 'pending'
                && latestNotifications.currentRequestId === requestId
            ) {
                state.latestNotifications.loading = 'idle'
                state.latestNotifications.currentRequestId = undefined
                state.latestNotifications.error = action.error
            }
        },

        [getLatestUnreadNotifications.pending]: (state, action) => {
            const { latestUnreadNotifications } = state
            const { requestId } = action.meta
            if (latestUnreadNotifications.loading === 'idle') {
                state.latestUnreadNotifications.loading = 'pending'
                state.latestUnreadNotifications.currentRequestId = requestId
                state.latestUnreadNotifications.error = null
            }
        },
        [getLatestUnreadNotifications.fulfilled]: (state, action) => {
            const { latestUnreadNotifications } = state
            const { requestId } = action.meta
            if (
                latestUnreadNotifications.loading === 'pending'
                && latestUnreadNotifications.currentRequestId === requestId
            ) {
                state.latestUnreadNotifications = {
                    data: action.payload.data,
                    totalUnreadNotifications: action.payload.meta.total,
                    loading: 'idle',
                    currentRequestId: undefined,
                    error: null
                }
            }
        },
        [getLatestUnreadNotifications.rejected]: (state, action) => {
            const { latestUnreadNotifications } = state
            const { requestId } = action.meta
            if (
                latestUnreadNotifications.loading === 'pending'
                && latestUnreadNotifications.currentRequestId === requestId
            ) {
                state.latestUnreadNotifications.loading = 'idle'
                state.latestUnreadNotifications.currentRequestId = undefined
                state.latestUnreadNotifications.error = action.error
            }
        },
    }
})

const { actions, reducer } = notificationsSlice

export default reducer