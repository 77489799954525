import React from 'react';
import queryString from 'query-string';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import MaintenanceCard from './components/Cards/MaintenanceCard';
import getMaintenancePeriod from './helpers/getMaintenancePeriod';

const MaintenanceChecker = ({ children, location }) => {
    const maintenancePeriod = getMaintenancePeriod();
    const now = moment.utc();
    const query = queryString.parse(location.search);
    const isTesting = !!(query && typeof query.test !== 'undefined');

    if(
        maintenancePeriod
        && now.isAfter(maintenancePeriod.start)
        && now.isBefore(maintenancePeriod.end)
        && !isTesting
    ) {
        return <MaintenanceCard maintenancePeriod={maintenancePeriod} />;
    }

    return children;
};

export default withRouter(MaintenanceChecker);
