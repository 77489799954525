import get from 'lodash/get';
import NProgress from 'nprogress';
import isObject from 'lodash/isObject';
import instance, { isAxiosError } from '../axios';
import { logout } from '../../../redux/auth/actions';

// Create a variable to hold the interceptor once it has been set.
let interceptor = undefined;

/**
 * Setup the interceptor on the axios instance which will
 * handle what needs to happen within the redux store when
 * a request returns an 'unauthenticated' error.
 * 
 * @param {object} store The redux store.
 * @returns 
 */
export const setupUnauthenticatedRequestReduxStoreIncerceptor = store => {
    // If we have already set the interceptor then we will exit.
    if (interceptor) {
        return;
    }
    
    // Set the interceptor and assign it to the interceptor variable.
    interceptor = instance.interceptors.response.use(response => {
        // Implement the redux store logic here.
        return response;
    }, error => {
        // If the response sends back an unauthenticated error
        // then we will dispatch logout() to the store.
        if (
            isAxiosError(error)
            && (
                (
                    isObject(get(error, 'response.data.errors'))
                    && Object.keys(get(error, 'response.data.errors')).some(key => {
                        return [
                            'unauthenticated',
                            'token_expired',
                            'token_invalid',
                            'token_blacklisted',
                            'token_not_provided'
                        ].indexOf(key) !== -1;
                    })
                )
                || get(error, 'response.data.code') === 'session_expired'
            )
        ) {
            NProgress.done();
            NProgress.remove();
            store.dispatch(
                logout({
                    skipApi: true,
                    error: 'Your old session has expired. Please login again.'
                })
            );
        }
        return Promise.reject(error);
    });
}

/**
 * Eject the interceptor, if any exists.
 */
export const ejectUnauthenticatedRequestReduxStoreIncerceptor = () => {
    if (interceptor) {
        instance.interceptors.response.eject(interceptor);
    }
}