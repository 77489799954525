import { isAxiosError } from '../api/axios';
import { call, put } from 'redux-saga/effects';

export function* sagaApiRequest({
    request,          // [Function] The function that will make the API request.
    args: _args,      // [any, any[]] The arguments to pass to the API request function.
    onRequestSuccess, // [Function] A function that will run when the API request is successfull.
    onSuccess,        // [String] The action type to dispatch on success.
    onError           // [String] The action type to dispatch on error.
}) {
    try {
        // Ensure the args are in an array format.
        const args = Array.isArray(_args) ? _args : [_args];

        // Make the request.
        const response = yield call(request, ...args);

        // On request success.
        if (onRequestSuccess) {
            yield call(onRequestSuccess, response);
        }

        // Yield the success action.
        yield put({
            type: onSuccess,
            payload: response.data
        });
    } catch(e) {
        yield put({
            type: onError,
            error: (isAxiosError(e) && e.response && e.response.data)
                ? e.response.data.error || e.response.data.errors || e.response.data
                : 'Internal server error'
        });
    }
}