import reducerRegistry from './reducerRegistry';
import auth from './auth/reducers';
import vehicles from './vehicles/reducer';
import documents from './documents/reducers';
import appointments from './appointments/reducers';
import travelMethods from './travelMethods/reducer';
import propertyQuestions from './propertyQuestions/reducer';
import notifications from './notifications/reducer';
import viewberBillingTypes from './viewberBillingTypes/reducers';
import academy from './academy/reducers'
import userCalendar from './calendar/reducer'
import feedbackFormValues from './feedbackForm/reducers'
import documentCategories from './documentCategories/reducers'

// We only register the initial defaul sagas here,
// Leaving the other ones for async loading
const defaultReducers = {
    academy,
    auth,
    vehicles,
    documents,
    appointments,
    travelMethods,
    propertyQuestions,
    notifications,
    viewberBillingTypes,
    userCalendar,
    feedbackFormValues,
    documentCategories
};

const registerDefaultReducers = () => {
    Object.entries(defaultReducers).forEach(([name, reducer]) => {
        reducerRegistry.register(name, reducer);
    });
};

export default registerDefaultReducers;
