import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { animateScroll as scroll } from 'react-scroll';
import { getApiErrorMessages } from '../../helpers/apiErrorMessages';
import {
    getFormResourceFromValues,
    getValidationSchemaFromFormResource,
    getValuesFromFormResource,
    setResourceFieldAttributeValue,
    updateFormResourceFromErrors,
} from '../../helpers/formResources';
import {
    apiResourceUpdateSuccessNotification,
    apiValidationErrorsNotification,
} from '../../helpers/toastNotification';
import {
    clearMetadataProfile,
    getProfile,
    updateProfile,
    uploadProfilePicture,
} from '../../redux/auth/actions';
import {
    getAllResources as getAllViewberBillingTypes,
} from '../../redux/viewberBillingTypes/actions';
import schema from '../../redux/users/schema';

const { REACT_APP_API_KEY } = process.env;

const withEditProfile = (ComponentToWrap, attributesToShow) => {

    class EditProfileHOC extends Component {
        state = {
            acceptedFiles: [],
            rejectedFiles: [],
            getting_profile: false,
            profile: null,
            profile_unchanged: true,
            updating_profile: false,
        };

        constructor(props) {
            super(props);

            this.handleUpdateProfile = this.handleUpdateProfile.bind(this);
            this.updateInputValue = this.updateInputValue.bind(this);
            this.uploadProfilePicture = this.uploadProfilePicture.bind(this);
        }

        uploadProfilePicture(acceptedFiles, rejectedFiles) {
            const { token, uploadProfilePicture } = this.props;

            // console.log(acceptedFiles);
            // console.log(rejectedFiles);

            this.setState({
                acceptedFiles: [],
                rejectedFiles,
            });

            acceptedFiles.forEach(photo => {
                this.setState({
                    acceptedFiles: [
                        Object.assign(photo, { preview: URL.createObjectURL(photo) }),
                    ],
                    updating_profile: true,
                });

                const data = {
                    photo,
                    token,
                    api_key: REACT_APP_API_KEY,
                    entity: 'user',
                };
                uploadProfilePicture({ data })
            });
        }

        updateInputValue(evt) {
            if(this.state.profile_unchanged === true) {
                this.setState({
                    profile_unchanged: false,
                });
            }

            if(evt.target.type === 'checkbox') {
                this.setState({
                    profile: {
                        ...this.state.profile,
                        [evt.target.name]: {
                            ...this.state.profile[evt.target.name],
                            value: evt.target.checked ? 1 : 0,
                        },
                    }
                });
                return;
            }

            this.setState({
                profile: {
                    ...this.state.profile,
                    [evt.target.name]: {
                        ...this.state.profile[evt.target.name],
                        value: evt.target.value
                    },
                }
            });
        }

        async handleUpdateProfile(evt) {
            evt.preventDefault();

            const { updateProfile, token } = this.props;
            const { profile } = this.state;
            const values = getValuesFromFormResource(profile);
            const validationSchema = getValidationSchemaFromFormResource(profile);
            const data = {
                token,
                ...values
            };

            // Reset errors
            this.setState({
                profile: updateFormResourceFromErrors(profile, {inner:[]})
            });

            await yup.object(validationSchema)
                .validate(
                    values,
                    { abortEarly: false }
                )
                .then(() => {
                    // If validation passes
                    // Update profile

                    updateProfile({ data });

                    this.setState({
                        updating_profile: true
                    });

                    scroll.scrollToTop();
                })
                .catch((errors) => {
                    // If validation does not passes
                    // Show notification
                    // And Set errors in the form

                    apiValidationErrorsNotification();

                    this.setState({
                        getting_profile: false,
                        profile: updateFormResourceFromErrors(profile, errors),
                        updating_profile: false,
                    });
                });
        }

        componentDidMount() {
            const {
                getAllViewberBillingTypes,
                getProfile,
                token,
                user,
                viewberBillingTypes,
            } = this.props;

            // console.log(profile);

            // If user is already in global state
            // Avoid re-fetching
            if(user === null) {
                const data = { token };

                this.setState({
                    getting_profile: true
                });

                getProfile({ data });

            } else {
                this.setState({ profile: user });
            }

            if(viewberBillingTypes.length === 0) {
                // Get all viewber billing types if none
                // Worth re-fetching every time
                const data = { token };

                getAllViewberBillingTypes({ data });

            } else {
                // If viewberBillingTypes are already in global state
                // Avoid re-fetching
            }
        }

        componentDidUpdate(prevProps) {
            const {
                clearMetadataProfile,
                errors,
                updated_profile,
                user,
            } = this.props;
            const {
                acceptedFiles,
                getting_profile,
                updating_profile,
            } = this.state;

            // This means that I was updating the resource,
            // And I received errors from the store
            // So it's time to restore the Update button
            if (
                updating_profile === true
                && typeof errors.length !== 'undefined'
                && errors.length !== 0
            ) {
                apiValidationErrorsNotification();

                this.setState({
                    getting_profile: false,
                    updating_profile: false,
                    rejectedFiles: [
                        ...acceptedFiles
                    ],
                    acceptedFiles: [],
                });
            }

            // This means that I was getting the resource,
            // And I received errors from the store
            // So it's time to restore the Update button
            else if (
                getting_profile === true
                && typeof errors.length !== 'undefined'
                && errors.length !== 0
            ) {
                this.setState({
                    getting_profile: false,
                    updating_profile: false
                });
            }

            // This means that I was getting the resource,
            // And I received  the profile from the store
            // So it's time to restore the internal state
            else if (
                getting_profile === true
                && prevProps.user === null
                && user !== null
            ) {
                this.setState({
                    profile: user,
                    getting_profile: false,
                    updating_profile: false
                });
            }

            // This means that I was updating the resource,
            // And I received either an updated profile from the store
            // So it's time to restore the internal state
            else if (
                updating_profile === true
                && updated_profile === true
            ) {
                apiResourceUpdateSuccessNotification({
                    resourceDisplayName: 'Profile',
                });

                this.setState({
                    getting_profile: false,
                    updating_profile: false,
                    acceptedFiles: [],
                    rejectedFiles: [],
                    profile: user,
                });

                clearMetadataProfile();
            }

            // If the billing type id values have come back from the API
            else if(
                prevProps.user
                && user
                && user.billing_type_id
                && user.billing_type_id.values
                && (
                    !prevProps.user.billing_type_id
                    || prevProps.user.billing_type_id.values.length === 0
                )
                && user.billing_type_id.values.length > 0
            ) {
                this.setState({
                    profile: user,
                });
            }
        }

        componentWillUnmount() {
            const { clearMetadataProfile } = this.props;
            const { acceptedFiles } = this.state;

            if(typeof clearMetadataProfile !== 'undefined') {
                clearMetadataProfile();
            }

            // Make sure to revoke the data uris to avoid memory leaks
            acceptedFiles.forEach(file => {
                URL.revokeObjectURL(file.preview);
            });
        }

        render() {
            // console.log(this.state.acceptedFiles);
            // console.log(this.props.user);

            return (
                <ComponentToWrap
                    onInputChange={this.updateInputValue}
                    onProfilePictureDrop={this.uploadProfilePicture}
                    onSubmit={this.handleUpdateProfile}
                    {...this.state}
                    {...this.props}
                />
            );
        }
    }

    const mapStateToProps = (state) => {
        const {
            error,
            token,
            updated_profile,
            user,
        } = state.auth;
        
        const errors = getApiErrorMessages(error);
        
        const viewberBillingTypeValues = state.viewberBillingTypes.resources.map(billingType => ({
            text: billingType.description,
            value: billingType.id
        }));

        let profile;

        if (typeof user === 'undefined') {
            profile = null;
        } else {
            profile = getFormResourceFromValues(user, schema, attributesToShow);

            if(profile && viewberBillingTypeValues.length > 0) {
                profile = setResourceFieldAttributeValue(profile, 'billing_type_id', 'values', viewberBillingTypeValues);
            }
        }

        return {
            errors,
            user: profile,
            token,
            updated_profile,
            viewberBillingTypes: viewberBillingTypeValues,
        };
    };

    const mapDispatchToProps = {
        clearMetadataProfile,
        getAllViewberBillingTypes,
        getProfile,
        updateProfile,
        uploadProfilePicture,
    };

    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(EditProfileHOC);
}

export default withEditProfile;
