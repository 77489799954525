import { all, takeEvery, fork } from 'redux-saga/effects';
import actions from './actions';
import { sagaApiRequest } from '../helpers';
import * as documentCategoriesAPI from '../../api/documentCategories';

export function* fetchByCategoryID() {
    yield takeEvery(actions.GET_BY_CATEGORY_ID_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: documentCategoriesAPI.find,
            args: payload,
            onSuccess: actions.GET_BY_CATEGORY_ID_REQUEST_SUCCESS,
            onError: actions.GET_BY_CATEGORY_ID_REQUEST_ERROR
        });
    });
}

export default function* rootSaga() {
    yield all([
        fork(fetchByCategoryID),
    ]);
}
