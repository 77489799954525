import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import logo from '../../assets/img/brand/viewber-logo-web-header.png';
import { getProfile, logout } from '../../redux/auth/actions';
import ChatsDropdown from './ChatsDropdown';
import NotificationsDropdown from './NotificationsDropdown';
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    UncontrolledDropdown
} from 'reactstrap';

const {
    REACT_APP_FILE_UPLOAD_BASE_URL
} = process.env;

class DefaultHeader extends Component {
    constructor(props) {
        super(props);
        this.handleLogout = this.handleLogout.bind(this);
    }

    handleLogout() {
        if (typeof this.props.logout !== 'undefined') {
            this.props.logout({token: this.props.token});
        }
    }

    componentDidMount() {
        // If user is already in global state
        // Avoid re-fetching
        if (this.props.user === null) {
            this.props.getProfile({ data: {token: this.props.token} });
        } else {
            // If resources for that page have been fetched before
            // avoid re-fetching
        }
    }
    
    render() {
        const { user } = this.props;

        return (
            <>
                <AppSidebarToggler className="d-lg-none" display="md" mobile />
                <Link to={'/dashboard'}>
                    <AppNavbarBrand
                        tag={'div'}
                        to="/dashboard"
                        full={{ src: logo, width: 120, alt: 'Viewber Logo' }}
                    />
                </Link>
                <AppSidebarToggler className="d-md-down-none" display="lg" />
                <Nav className="ml-auto" navbar>
                    <ChatsDropdown />
                    <NotificationsDropdown />
                    <UncontrolledDropdown className="nav-item" direction="down">
                        <DropdownToggle nav>
                            {
                                user && user.photo
                                    ? <img
                                        src={REACT_APP_FILE_UPLOAD_BASE_URL+user.photo}
                                        className="img-avatar"
                                        alt={user.full_name}
                                    />
                                    : <i className="fa fa-user fa-lg"></i>
                            }
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem onClick={this.handleLogout} role="menuitem">
                                <i className="fa fa-lock"></i> Logout
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Nav>
            </>
        );
    }
}

const mapStateToProps = state => ({
    user: typeof state.auth.user === 'undefined' ? null : state.auth.user,
    token: state.auth.token
});

const mapDispatchToProps = {
    getProfile,
    logout
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DefaultHeader);
