import 'react-app-polyfill/ie9'; // For IE 9-11 support
import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import axios from './api/axios';
import get from 'lodash/get';
import { Chat, ConsoleLogger, NullLogger } from '@viewberapp/chat';
import App from './App';
import store from './redux/store';
import * as serviceWorker from './serviceWorker';

const queryClient = new QueryClient();

Chat.init({
    axios,
    logger: new NullLogger,
    // The console logger is only suitable for
    // debugging unless you dont mind users seeing
    // internal logs.
    // logger: new ConsoleLogger,
    jwtToken: get(store.getState(), 'auth.token'),
    routes: {
        getReportChatRoute: chatId => `${process.env.REACT_APP_API_BASE_URL}/api/v1/chats/${chatId}/report`,
        getChatMessagesRoute: chatId => `${process.env.REACT_APP_API_BASE_URL}/api/v1/chats/${chatId}/messages`,
        getChatAblyTokenRoute: () => `${process.env.REACT_APP_API_BASE_URL}/api/v1/chats/ably/token`,
        getChatStoreMessageRoute: chatId => `${process.env.REACT_APP_API_BASE_URL}/api/v1/chats/${chatId}/messages`,
        getChatForAppointmentRoute: appointmentId => `${process.env.REACT_APP_API_BASE_URL}/api/v1/chats/for-appointment/${appointmentId}`,
        markChatMessagesReadUpToRoute: (chatId, messageId) => `${process.env.REACT_APP_API_BASE_URL}/api/v1/chats/${chatId}/mark-read-up-to/${messageId}`,
        getUserChatUnreadMessageCountRoute: () => `${process.env.REACT_APP_API_BASE_URL}/api/v1/chats/unread-message-count`,
    }
});

function render(Component) {
    ReactDOM.render(
        (
            <Provider store={store}>
                <HelmetProvider>
                    <QueryClientProvider client={queryClient}>
                        <Component />
                    </QueryClientProvider>
                </HelmetProvider>
            </Provider>
        ),
        document.getElementById('root')
    );
}

render(App);

// if(module.hot) {
//     module.hot.accept('./App', () => {
//         const NextApp = require('./App').default;
//
//         render(NextApp);
//     })
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
