import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    checkItRequiresCalendarUpdate,
} from '../redux/calendar/reducer'

class CalendarUpdateRestrictedComponent extends Component {
    componentDidMount() {
        const {
            hasCheckedItRequiresCalendarUpdate,
        } = this.props;

        if(! hasCheckedItRequiresCalendarUpdate) {
            checkItRequiresCalendarUpdate();
        }
    }

    render() {
        const {
            hasCheckedItRequiresCalendarUpdate,
            requiresCalendarUpdate,
            location
        } = this.props

        if (
            hasCheckedItRequiresCalendarUpdate === true
            && requiresCalendarUpdate === true
            && location.pathname !== '/dashboard'
            && location.pathname !== '/calendar'
            && location.pathname !== '/unavailability/create'
        ) {
            return <Redirect to="/dashboard" />;
        }

        return this.props.children;
    }
}

const mapStateToProps = (state) => {

    const {
        requiresCalendarUpdate,
        hasCheckedItRequiresCalendarUpdate,
    } = state.userCalendar

    return {
        hasCheckedItRequiresCalendarUpdate,
        requiresCalendarUpdate,
    };
};

const mapDispatchToProps = {
    checkItRequiresCalendarUpdate
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(CalendarUpdateRestrictedComponent)
);
