import sagaRegistry from './sagaRegistry';
import auth from './auth/sagas';
import appointments from './appointments/sagas';
import documents from './documents/sagas';
import viewberBillingTypes from './viewberBillingTypes/sagas';
import academy from './academy/sagas'
import documentCategories from './documentCategories/sagas'

// We only register the initial default sagas here,
// Leaving the other ones for async loading
const defaultSagas = {
    academy,
    auth,
    documentCategories,
    appointments,
    documents,
    viewberBillingTypes,
};

const registerDefaultSagas = () => {
    Object.entries(defaultSagas).forEach(([name, saga]) => {
        sagaRegistry.register(name, saga);
    });
};

export default registerDefaultSagas;
