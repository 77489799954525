export const getResourceFromPaginatedResourcesAndId = (resources, id) => {
    if(
        resources === null
        || typeof resources === 'undefined'
        || (Object.keys(resources).length === 0 && resources.constructor === Object)
    ) {
        return null;
    }

    let resource = null;
    Object.entries(resources).forEach(([page, paginated_resources]) => {
        if(paginated_resources && paginated_resources.length) {
            const resource_search = paginated_resources.find(resource => resource.id === parseInt(id, 10));

            if(resource_search !== null) {
                resource = {...resource_search};
            }
        }
    });

    return resource;
};

/**
 * Replaces the updated resource in the paginated set of resources.
 */
export const updatePaginatedResourcesFromResource = (paginated_resources, new_resource) => {

    // Create new object
    let new_resources = {};

    // console.log(paginated_resources, new_resource);

    // We skip the update if either of the paginated resources is not defined
    // Which means resources have not been fetched yet
    if(
        typeof paginated_resources !== 'undefined'
        && paginated_resources !== null
    ) {
        Object.entries(paginated_resources).forEach(([page, page_resources]) => {

            // console.log(page, page_resources);

            new_resources[page] = page_resources.map((resource, idx) => {

                // console.log(idx, resource);

                if(resource.id === new_resource.id) {
                    return {
                        ...new_resource
                    };
                }

                return resource;
            });
        });
    }

    // console.log(new_resources);

    return new_resources;
};

export const paginateResources = (resources, pageSize) => {
    let page = 1;

    if(resources.length === 0) {
        return {
            [page]: []
        };
    }

    // console.log(resources);

    const paginatedResources = resources.reduce(
        (
            result,
            resource
        ) => {
            if(result[page]) {
                if(result[page].length >= pageSize) {
                    page++;
                }
            }

            if(!result[page]){
                result[page] = [];
            }

            return {
                ...result,
                [page]: [
                    ...result[page],
                    resource,
                ],
            };
        },
        {}
    );

    return paginatedResources;
};
