import apiRequest from '../apiRequest'

export const getVehicles = token =>
    apiRequest(`${process.env.REACT_APP_API_BASE_URL}/api/v1/vehicles`, {
        token
    })

export const getVehicle = (id, token) =>
    apiRequest(`${process.env.REACT_APP_API_BASE_URL}/api/v1/vehicles/${id}`, {
        token
    })

export const deleteVehicle = (id, token) =>
    apiRequest(`${process.env.REACT_APP_API_BASE_URL}/api/v1/vehicles/delete/${id}`, {
        method: 'DELETE',
        token
    })

export const getCurrentVehicle = token =>
    apiRequest(`${process.env.REACT_APP_API_BASE_URL}/api/v1/vehicles/current`, {
        token
    })