import createActionName from '../createActionName';

export const reducerName = 'documentCategories';

export const GET_BY_CATEGORY_ID_REQUEST = createActionName(reducerName, 'GET_BY_CATEGORY_ID_REQUEST');
export const GET_BY_CATEGORY_ID_REQUEST_SUCCESS = createActionName(reducerName, 'GET_BY_CATEGORY_ID_REQUEST_SUCCESS');
export const GET_BY_CATEGORY_ID_REQUEST_ERROR = createActionName(reducerName, 'GET_BY_CATEGORY_ID_REQUEST_ERROR');

// actions
const actions = {
	GET_BY_CATEGORY_ID_REQUEST,
	GET_BY_CATEGORY_ID_REQUEST_SUCCESS,
	GET_BY_CATEGORY_ID_REQUEST_ERROR,
};

export const fetchByCategoryID = payload => ({
    type: actions.GET_BY_CATEGORY_ID_REQUEST,
    payload,
});


export default actions;
