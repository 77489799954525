import { all, takeEvery, put, call, fork } from 'redux-saga/effects';
import actions from './actions';
import { isAxiosError } from '../../api/axios';
import { sagaApiRequest } from '../helpers';
import * as authAPI from '../../api/auth';
import { Chat } from '@viewberapp/chat';

export function* getProfileRequest() {
    yield takeEvery(actions.GET_PROFILE_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: authAPI.profile,
            args: payload.data,
            onSuccess: actions.GET_PROFILE_SUCCESS,
            onError: actions.GET_PROFILE_ERROR
        });
    });
}

export function* loginRequest() {
    yield takeEvery(actions.LOGIN_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: authAPI.login,
            onRequestSuccess: response => {
                Chat.setJwtToken(response.data.token);
            },
            args: payload.data,
            onSuccess: actions.LOGIN_SUCCESS,
            onError: actions.LOGIN_ERROR
        });
    });
}

export function* loginAsRequest() {
    yield takeEvery(actions.LOGIN_AS_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: authAPI.loginAs,
            onRequestSuccess: response => {
                Chat.setJwtToken(response.data.token);
            },
            args: payload.data,
            onSuccess: actions.LOGIN_AS_SUCCESS,
            onError: actions.LOGIN_AS_ERROR
        });
    });
}

export function* logoutRequest() {
    yield takeEvery(actions.LOGOUT_REQUEST, function*({ payload }) {
        try {
            // Log out via the API.
            if (!payload.skipApi) {
                const response = yield call(authAPI.logout, {
                    token: payload.token
                });
            }

            // Yield success.
            yield put({
                type: actions.LOGOUT_SUCCESS,
                payload: {
                    // Allow the user to add an error to logout success.
                    error: payload.error
                }
            });
        } catch(e) {
            yield put({
                type: actions.LOGOUT_SUCCESS, // On error, we still use LOGOUT_SUCCESS.
                error: (isAxiosError(e) && e.response && e.response.data)
                    ? e.response.data.error || e.response.data.errors || e.response.data
                    : 'Internal server error'
            });
        } finally {
            // Log out of the Chat.
            Chat.logout();
        }
    });
}

function* passwordResetEmailRequest() {
    yield takeEvery(actions.PASSWORD_RESET_EMAIL_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: authAPI.passwordResetEmail,
            args: payload.data,
            onSuccess: actions.PASSWORD_RESET_EMAIL_SUCCESS,
            onError: actions.PASSWORD_RESET_EMAIL_ERROR
        });
    });
}

function* passwordResetRequest() {
    yield takeEvery(actions.PASSWORD_RESET_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: authAPI.passwordReset,
            args: payload.data,
            onSuccess: actions.PASSWORD_RESET_SUCCESS,
            onError: actions.PASSWORD_RESET_ERROR
        });
    });
}

export function* registerRequest() {
    yield takeEvery(actions.REGISTER_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: authAPI.register,
            args: payload.data,
            onSuccess: actions.REGISTER_SUCCESS,
            onError: actions.REGISTER_ERROR
        });
    });
}

export function* setReadSuzyWebsiteRequest() {
    yield takeEvery(actions.SET_READ_SUZY_WEBSITE_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: authAPI.readSuzy,
            args: payload.data,
            onSuccess: actions.SET_READ_SUZY_WEBSITE_SUCCESS,
            onError: actions.SET_READ_SUZY_WEBSITE_ERROR
        });
    });
}

export function* updateProfileRequest() {
    yield takeEvery(actions.UPDATE_PROFILE_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: authAPI.updateProfile,
            args: payload.data,
            onSuccess: actions.UPDATE_PROFILE_SUCCESS,
            onError: actions.UPDATE_PROFILE_ERROR
        });
    });
}

export function* uploadProfilePictureRequest() {
    yield takeEvery(actions.UPLOAD_PROFILE_PICTURE_REQUEST, function*({ payload }) {
        const { token, ...data } = payload.data;
        try {
            const result = yield call(authAPI.uploadProfilePicture, data);
            if (result.success && result.photo) {
                const profileData = {
                    photo_pending: result.photo,
                    token,
                };
                yield put({
                    type: actions.UPDATE_PROFILE_REQUEST,
                    payload: { data: profileData },
                });
            } else {
                yield put({
                    type: actions.UPLOAD_PROFILE_PICTURE_ERROR,
                    error: result.error || result.errors || result
                });
            }
        } catch(err) {
            yield put({
                type: actions.UPLOAD_PROFILE_PICTURE_ERROR,
                error: 'Internal server error'
            });
        }
    });
}

export function* getIdentityRequest() {
    yield takeEvery(actions.GET_IDENTITY_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: authAPI.identity,
            args: payload.data,
            onSuccess: actions.GET_IDENTITY_SUCCESS,
            onError: actions.GET_IDENTITY_ERROR
        });
    });
}

export function* verifyTwoFactorCodeRequest() {
    yield takeEvery(actions.VERIFY_TWO_FACTOR_CODE_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: authAPI.twoFactorCodeVerify,
            args: payload.data,
            onSuccess: actions.VERIFY_TWO_FACTOR_CODE_SUCCESS,
            onError: actions.VERIFY_TWO_FACTOR_CODE_ERROR
        });
    });
}

export function* resendTwoFactorCode() {
    yield takeEvery(actions.RESEND_TWO_FACTOR_CODE_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: authAPI.twoFactorCodeResend,
            args: payload.data,
            onSuccess: actions.RESEND_TWO_FACTOR_CODE_SUCCESS,
            onError: actions.RESEND_TWO_FACTOR_CODE_ERROR
        });
    });
}

export default function* rootSaga() {
    yield all([
        fork(getProfileRequest),
        fork(loginRequest),
        fork(loginAsRequest),
        fork(logoutRequest),
        fork(passwordResetEmailRequest),
        fork(passwordResetRequest),
        fork(registerRequest),
        fork(setReadSuzyWebsiteRequest),
        fork(updateProfileRequest),
        fork(uploadProfilePictureRequest),
        fork(getIdentityRequest),
        fork(verifyTwoFactorCodeRequest),
        fork(resendTwoFactorCode),
    ]);
}
