import apiRequest from '../apiRequest'

export const checkItRequiresCalendarUpdate = token =>
    apiRequest(`${process.env.REACT_APP_API_BASE_URL}/api/v1/user-check-requires-calendar-updates`, {
        token
    })

export const confirmCalendarUpdated = token =>
    apiRequest(`${process.env.REACT_APP_API_BASE_URL}/api/v1/user-confirm-calendar-updated`, {
        method: 'POST',
        token
    })

