import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
} from 'reactstrap';
import ApiErrorAlert from '../../components/Alerts/ApiErrorAlert';
import { getApiErrorMessages } from '../../helpers/apiErrorMessages';
import { passwordReset } from '../../redux/auth/actions';
import logo from '../../assets/img/brand/viewber_logo.png';

const { REACT_APP_API_KEY } = process.env;

class PasswordReset extends Component {
    constructor(props) {
        super(props);

        this.handlePasswordReset = this.handlePasswordReset.bind(this);
        this.handleUpdateInputValue = this.handleUpdateInputValue.bind(this);

        const urlParams = props.match.params;

        this.state = {
            email: urlParams.email ? urlParams.email : '',
            password: '',
            password_confirmation: '',
            token: urlParams.token ? urlParams.token : '',
            resetting: false,
        };
    }

    handlePasswordReset(evt) {
        const { passwordReset } = this.props;
        const { email, token, password, password_confirmation } = this.state;
        const data = {
            api_key: REACT_APP_API_KEY,
            email,
            password,
            password_confirmation,
            token,
        };

        evt.preventDefault();

        this.setState({
            resetting: true,
        });

        passwordReset({ data });
    }

    handleUpdateInputValue(evt) {
        this.setState({
            [evt.target.name]: evt.target.value,
        });
    }

    componentDidUpdate(prevProps) {
        const { errors, password_reset } = this.props;
        const { resetting } = this.state;

        if(
            resetting === true
            && errors.length > 0
        ) {
            this.setState({
                resetting: false,
            });
        }

        else if(
            resetting === true
            && password_reset === true
        ) {
            this.setState({
                email: '',
                resetting: false,
            });
        }
    }

    render() {
        const { errors, password_reset } = this.props;
        const auth_token = this.props.token;
        const {
            email,
            password,
            password_confirmation,
            resetting,
            token
        } = this.state;
        const passwordResetButtonIconClassName = resetting === true
            ? 'fa fa-fw fa-spinner fa-spin'
            : 'fa fa-fw fa-key';

        if(auth_token) {
            // If already logged in
            // redirect to dashboard
            return <Redirect to="/dashboard" />;
        }

        if(password_reset) {
            return <Redirect to="/login" />;
        }

        return (
            <>
                <Helmet>
                    <title>Password Reset | Viewber Dashboard</title>
                </Helmet>
                <div className="app flex-row align-items-center">
                    <Container>
                        <Row className="justify-content-center">
                            <Col className="col-lg-6 col-md-8">
                                <Card>
                                    <CardBody className="text-center">
                                        <Row className="mb-3">
                                            <Col className="col-md-12 text-center">
                                                <img
                                                    src={logo}
                                                    alt="Viewber Logo"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col className="col-md-12 text-center">
                                                <h1>Password Reset</h1>
                                            </Col>
                                        </Row>
                                        <ApiErrorAlert errors={errors} />
                                        <Form onSubmit={this.handlePasswordReset}>
                                            <InputGroup className="mb-3">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="fa fa-fw fa-envelope"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    value={email}
                                                    onChange={this.handleUpdateInputValue}
                                                    placeholder="e.g. john.smith@acme.com"
                                                    aria-label="Email"
                                                />
                                            </InputGroup>
                                            <InputGroup className="mb-3">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="fa fa-fw fa-key"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="password"
                                                    id="password"
                                                    name="password"
                                                    value={password}
                                                    onChange={this.handleUpdateInputValue}
                                                    placeholder="Password"
                                                    aria-label="Password"
                                                />
                                            </InputGroup>
                                            <InputGroup className="mb-3">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="fa fa-fw fa-key"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="password"
                                                    id="password_confirmation"
                                                    name="password_confirmation"
                                                    value={password_confirmation}
                                                    onChange={this.handleUpdateInputValue}
                                                    placeholder="Confirm Password"
                                                    aria-label="Confirm Password"
                                                />
                                            </InputGroup>
                                            <Input
                                                type="hidden"
                                                id="token"
                                                name="token"
                                                value={token}
                                                onChange={this.handleUpdateInputValue}
                                            />
                                            <Row>
                                                <Col className="col-md-5 col-5 text-left">
                                                    <Button
                                                        onClick={this.handlePasswordReset}
                                                        type="submit"
                                                        disabled={resetting}
                                                        color="success"
                                                        block
                                                    >
                                                        <i className={passwordResetButtonIconClassName}></i>
                                                        {' '}
                                                        Reset Password
                                                    </Button>
                                                </Col>
                                                <Col className="col-md-7 col-7 text-right pl-0">
                                                    <Link to="/login">Back To Login</Link>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    const { error, password_reset, token } = state.auth;
    const errors = getApiErrorMessages(error);

    return {
        errors,
        password_reset,
        token,
    };
};

const mapDispatchToProps = {
    passwordReset,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PasswordReset);
