import { combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import throttle from 'lodash/throttle';
import { loadState, saveState } from './localStorage';
import reducerRegistry from './reducerRegistry';
import registerDefaultReducers from './reducers';
import sagaRegistry from './sagaRegistry';
import registerDefaultSagas from './sagas';
import { configureStore as toolkitConfigureStore } from '@reduxjs/toolkit';
import { setupUnauthenticatedRequestReduxStoreIncerceptor } from '../api/axios/interceptors';

const { NODE_ENV, REACT_APP_STATE_DRIVER } = process.env;

export const configureStore = () => {
    let store;
    let preloadedState = undefined;
    
    const sagaMiddleware = createSagaMiddleware();

    if (REACT_APP_STATE_DRIVER === "localStorage") {
        preloadedState = loadState();
    }

    registerDefaultReducers();
    registerDefaultSagas();

    if (preloadedState) {
        // We register additional reducers
        // in case they are needed from preloaded state
        Object.entries(preloadedState).forEach(([name, reducer]) => {
            reducerRegistry.register(name, reducer);
        });
    }

    const rootReducer = combineReducers(reducerRegistry.getReducers());

    store = toolkitConfigureStore({
        reducer: rootReducer,
        middleware: getDefaultMiddleware => getDefaultMiddleware().concat(sagaMiddleware),
        preloadedState
    });

    // Setup the axios interceptors.
    setupUnauthenticatedRequestReduxStoreIncerceptor(store);

    if (REACT_APP_STATE_DRIVER === "localStorage") {
        store.subscribe(throttle(() => {
            saveState(store.getState());
        }, 1000));
    }

    // We set an event listener for the reducer registry
    // So that whenever a new reducer gets added
    // We replace the reducers with the new ones
    reducerRegistry.setChangeListener(reducers => {
        store.replaceReducer(combineReducers(reducers));
    });

    // We set an event listener for the saga registry
    // So that whenever a new saga gets added
    // We replace the sagas with the new ones
    sagaRegistry.setChangeListener((sagas) => {
        function* allSagas(getState) {
            yield all(sagas);
        }

        sagaMiddleware.run(allSagas);
    });

    function* allSagas(getState) {
        yield all(sagaRegistry.getSagas());
    }

    sagaMiddleware.run(allSagas);

    return store;
}

const store = configureStore();

export default store;
