export const keyField = "id";

export const nameField = "viewing.id";

export const columns = [
    {
        dataField: 'full_address',
        text: 'Address',
        sort: true,
        className: 'col-md-12',
        formatter: (value) => value ? value : 'N/A',
    },
    {
        dataField: 'postcode',
        text: 'Postcode',
        sort: true,
        className: 'col-md-3 col-12',
        formatter: (value) => value ? value.toUpperCase() : 'N/A',
    }
];

export const sortingOptions = [
    {
        display_name: 'Postcode',
        name: 'postcode',
        direction: 'desc',
    },
    {
        display_name: 'Postcode',
        name: 'postcode',
        direction: 'asc',
    },
    {
        display_name: 'Address',
        name: 'full_address',
        direction: 'desc',
    },
    {
        display_name: 'Address',
        name: 'full_address',
        direction: 'asc',
    },
];

export const defaultSortingOption = { ...sortingOptions[0] };

export const searchColumns = [
    'Postcode',
    'Address',
];

export const pageSize = 10;

export const schema = {
    // display_name: {
    //     type: 'text',
    //     value: '',
    //     rules: yup.string()
    //         .required()
    //         .max(255),
    //     errors: [],
    // },
};

export default schema;
