import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
    Button,
	Card,
	CardBody,
	Col,
	Container,
	Form,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Row
} from 'reactstrap';
import FormInput from '../../components/FormInput';
import ApiErrorAlert from '../../components/Alerts/ApiErrorAlert';
import { getApiErrorMessages } from '../../helpers/apiErrorMessages';
import { login, resetLoginError } from '../../redux/auth/actions';
import loginLogo from '../../assets/img/brand/viewber_logo.png';

const {
    REACT_APP_API_KEY,
    REACT_APP_WEBSITE_HOME,
} = process.env;

export class Login extends Component {
    state = {
        email: '',
        password: '',
    };

    constructor(props) {
        super(props);

        this.handleLogin = this.handleLogin.bind(this);
        this.updateInputValue = this.updateInputValue.bind(this);
    }

    handleLogin(evt) {
        evt.preventDefault();

        const { resetLoginError, errors, login } = this.props;
        const { email, password } = this.state;
        
        const data = {
            api_key: REACT_APP_API_KEY,
            email,
            password,
        };

        if(errors.length > 0) {
            resetLoginError();
        }

        login({ data });
    };

    updateInputValue(evt) {
        const { resetLoginError, errors } = this.props;

        if(errors.length > 0) {
            resetLoginError();
        }

        this.setState({
            [evt.target.name]: evt.target.value,
        });
    }

    render() {
        const {
            errors,
            logged_in,
            logged_out,
            logging_in,
            must_complete_mfa
        } = this.props;

        if (must_complete_mfa === true) {
            return <Redirect to="/2fa" />;
        }

        if(logged_in === true) {
            return <Redirect to="/" />;
        }

        const loginButtonIconClassName = typeof logging_in !== 'undefined' && logging_in === true
            ? 'fa fa-spinner fa-spin'
            : 'fa fa-sign-in';

        return (
            <div className="app flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="6">
                            <Card className="p-2">
                                <CardBody>
                                    <img
                                        src={loginLogo}
                                        alt="Viewber logo"
                                        className="img-fluid"
                                    />
                                    <h1>Login</h1>
                                    <p className="text-muted">Sign In to your account</p>
                                    <ApiErrorAlert errors={errors} />
                                    <Form innerRef={"login-form"}>
                                        <InputGroup className="mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fa fa-fw fa-envelope"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <FormInput
                                                type="email"
                                                name="email"
                                                placeholder="e.g. john.smith@domain.com"
                                                onChange={this.updateInputValue}
                                            />
                                        </InputGroup>
                                        <InputGroup className="mb-4">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fa fa-fw fa-key"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <FormInput
                                                type="password"
                                                name="password"
                                                placeholder="Password"
                                                onChange={this.updateInputValue}
                                            />
                                        </InputGroup>
                                        <Row>
                                            <Col className="col-md-6">
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    className="px-2"
                                                    disabled={logging_in}
                                                    onClick={this.handleLogin}
                                                    block
                                                >
                                                    <i className={loginButtonIconClassName}></i>
                                                    {' '}
                                                    Login
                                                </Button>
                                            </Col>
                                            <Col className="col-md-6 text-right">
                                                <Button
                                                    color="link"
                                                    className="px-0 text-right"
                                                    tag="a"
                                                    href={`/password/reset`}
                                                >
                                                    Forgot password?
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="col-md-12 text-right">
                                                <Button
                                                    color="link"
                                                    className="px-0 text-right"
                                                    tag="a"
                                                    href={`${REACT_APP_WEBSITE_HOME}/viewing-agents`}
                                                >
                                                    Sign up
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        error,
        logged_out,
        logging_in,
        token,
        must_complete_mfa
    } = state.auth;
    const errors = getApiErrorMessages(error);

    return {
        errors,
        must_complete_mfa,
        logged_in: token ? true : false,
        logged_out: logged_out === true,
        logging_in: logging_in === true,
    };
};

const mapDispatchToProps = {
    login,
    resetLoginError,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);
