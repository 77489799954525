export const getFormResourceFromValues = (values, schema, attributesToShow = []) => {
    if (
        typeof values === 'undefined'
        || values === null
    ) {
        return null;
    }

    const resource = Object.entries(values).reduce(
        (result, [key, value]) => {
            // console.log(schema, key);

            // If I'm not showing the attribute,
            // Skip the item (just progress with the result object)
            if (attributesToShow.indexOf(key) === -1) {
                return {
                    ...result
                };
            }

            // Disabled attribute
            let disabled = false;
            if (schema[key] && typeof schema[key].disabled !== 'undefined') {
                disabled = schema[key].disabled;
            } else if (key.length > 3 && key.indexOf('_at') === key.length - 3) {
                disabled = true;
            }

            // Label text
            const label = getSchemaValueFromKeyAndParameter(schema, key, 'label');

            // Empty option text (for select inputs)
            const empty_option = getSchemaValueFromKeyAndParameter(schema, key, 'empty_option');

            // Values array of values and texts (for select inputs)
            const values = getSchemaValueFromKeyAndParameter(schema, key, 'values');

            // Form Feedback to show additional message below form input
            const formText = getSchemaValueFromKeyAndParameter(schema, key, 'formText');

            // Date Format for display of react-datetime dependency
            const dateFormat = getSchemaValueFromKeyAndParameter(schema, key, 'dateFormat');

            // Time Format for display of react-datetime dependency
            const timeFormat = getSchemaValueFromKeyAndParameter(schema, key, 'timeFormat');

            // Is Valid Date function for hiding certain dates of react-datetime dependenencies
            const isValidDate = getSchemaValueFromKeyAndParameter(schema, key, 'isValidDate');

            let data = {
                type: getTypeFromKey(schema, key),
                value: value,
                errors: [],
                rules: getValidationRulesFromKey(schema, key),
                disabled: disabled,
                placeholder: getSchemaValueFromKeyAndParameter(schema, key, 'placeholder'),
            };

            if (typeof label !== 'undefined') {
                data.label = label;
            }
            if (typeof empty_option !== 'undefined') {
                data.empty_option = empty_option;
            }
            if (typeof values !== 'undefined') {
                data.values = values;
            }
            if (typeof formText !== 'undefined') {
                data.formText = formText;
            }
            if (typeof dateFormat !== 'undefined') {
                data.dateFormat = dateFormat;
            }
            if (typeof timeFormat !== 'undefined') {
                data.timeFormat = timeFormat;
            }
            if (typeof isValidDate !== 'undefined') {
                data.isValidDate = isValidDate;
            }

            return {
                ...result,
                [key]: {
                    ...data
                },
            };
        },
        {}
    );

    return resource;
};

export const getFormGroupsFromResource = (resource, onInputChange) => {
    if (!resource) {
        return [];
    }

    return Object.entries(resource).filter(([name, params], idx) => {
        if (
            // We skip everything that's not undefined
            typeof resource[name] === 'undefined'
            // we skip id
            || name === 'id'
        ) {
            return false;
        }

        if (typeof params.value === 'object' && params.value !== null && typeof params.value.length === 'undefined') {
            // We skip objects as we do not have any way of rendering them
            // But we do not skip arrays as we might need them for multiple selects
            // hence the typeof params.value.length === 'undefined'
            return false;
        }

        return true;

    }).map(([name, params], idx) => {
        const disabled = params.disabled
            ? params.disabled
            : false;
        const label = params.label
            ? params.label
            : name.charAt(0).toUpperCase() + name.substr(1).replace('_', ' ');
        const placeholder = params.placeholder
            ? params.placeholder
            : `Enter the ${label}`;
        const type = params.type
            ? params.type
            : "text";
        const value = params.value === null
            ? ''
            : params.value;
        const errors = params.errors
            ? params.errors
            : [];
        const empty_option = params.empty_option
            ? params.empty_option
            : '';
        const values = params.values
            ? params.values
            : [];
        const formText = params.formText
            ? params.formText
            : null;
        const multiple = params.multiple
            ? params.multiple
            : false;
        const dateFormat = params.dateFormat
            ? params.dateFormat
            : undefined;
        const timeFormat = params.timeFormat
            ? params.timeFormat
            : false;
        const isValidDate = params.isValidDate
            ? params.isValidDate
            : undefined;
        const closeOnSelect = params.closeOnSelect
            ? params.closeOnSelect
            : undefined;

        if (type === 'react-select') {
            // React select wants the full option as a value [sic.]
            const reactSelectValues = multiple === true
                // We extract all the options
                ? values.filter(option => {
                    // value is an array for multiple selects
                    // So we need to check if the option.value is contained in that value(s)
                    return value.indexOf(option.value) > -1;
                })
                // We extract the single option from the multiple select
                : values.filter(option => {
                    return option.value === value;
                });

            const reactSelectValue = multiple === true
                ? [...reactSelectValues]
                : (
                    reactSelectValues.length > 0
                        ? reactSelectValues[0]
                        : null
                );

            return {
                disabled: disabled,
                formFeedback: errors.length ? errors.join('. ') : null,
                key: name,
                invalid: errors.length > 0,
                label: label,
                multiple: multiple,
                name: name,
                onChange: (selectedOption, extra) => onInputChange(selectedOption, { ...extra, name, multiple }),
                placeholder: placeholder,
                type: type,
                value: reactSelectValue,
                values: values,
            }
        }

        if (type === 'checkbox') {
            return {
                checked: value === 1,
                className: 'pb-3',
                disabled: disabled,
                formFeedback: errors.length ? errors.join('. ') : null,
                formText: formText,
                invalid: errors.length > 0,
                key: name,
                label: ` ${label}`,
                name: name,
                onChange: onInputChange,
                type: type,
                value: value,
            };
        }

        if (type === 'hidden') {
            return {
                disabled: disabled,
                formText: formText,
                key: name,
                name: name,
                onChange: onInputChange,
                type: type,
                value: value,
            };
        }

        if (type === 'react-datetime') {
            return {
                closeOnSelect: closeOnSelect,
                dateFormat: dateFormat,
                disabled: disabled,
                formFeedback: errors.length ? errors.join('. ') : null,
                formText: formText,
                invalid: errors.length > 0,
                isValidDate: isValidDate,
                key: name,
                label: label,
                locale: 'en-gb',
                name: name,
                onChange: onInputChange,
                placeholder: placeholder,
                timeFormat: timeFormat,
                type: type,
                value: value,
                viewMode: 'days',
            };
        }

        return {
            disabled: disabled,
            emptyOption: empty_option,
            formFeedback: errors.length ? errors.join('. ') : null,
            formText: formText,
            invalid: errors.length > 0,
            key: name,
            label: label,
            multiple: multiple,
            name: name,
            onChange: onInputChange,
            placeholder: placeholder,
            type: type,
            value: value,
            values: values,
        };
    });
}

export const getSchemaValueFromKeyAndParameter = (schema, key, parameter) => {
    return schema[key] && schema[key][parameter]
        ? schema[key][parameter]
        : undefined;
};

export const getTypeFromKey = (schema, key) => {
    return schema[key] && schema[key].type
        ? schema[key].type
        : 'text';
};

export const getValidationRulesFromKey = (schema, key) => {
    return schema[key] && schema[key].rules
        ? schema[key].rules
        : undefined;
};

export const getValidationSchemaFromFormResource = (resource) => {
    if (!resource) {
        return {};
    }

    // console.log(resource);

    const schema = Object.entries(resource).reduce(
        (result, [name, parameters]) => {
            // console.log(result);

            if (parameters.rules) {
                return {
                    ...result,
                    [name]: parameters.rules
                };
            }

            // If no rules set,
            // avoid setting rules
            return {
                ...result
            };
        },
        {}
    );

    // console.log(schema);

    return schema;
};

export const getValuesFromFormResource = (resource) => {
    if (
        typeof resource === 'undefined'
        || resource === null
    ) {
        return {};
    }

    const values = Object.entries(resource).reduce(
        (result, [name, parameters]) => {
            // console.log(result);

            return {
                ...result,
                [name]: parameters.value
            };
        },
        {}
    );

    // console.log(values);

    return values;
};

export const updateFormResourceFromErrors = (resource, errors) => {
    const new_resource = Object.entries(resource).reduce(
        (result, [name, parameters]) => {
            // console.log(result, name, parameters);

            return {
                ...result,
                [name]: {
                    ...parameters,
                    errors: errors.inner.filter((error) => {
                        return name === error.path;
                    })
                        .map((error) => {
                            return error.message;
                        })
                }
            };
        },
        {}
    );

    return new_resource;
};

export const setResourceFieldAttributeValue = (schema, field, attribute, value) => {
    return {
        ...schema,
        [field]: {
            ...schema[field],
            [attribute]: value
        }
    };
};

export const get = (target, field) => {
    if (field === 'resource') {
        return target;
    }

    const pathArray = splitNested(field);
    let result = void 0;

    try {
        result = pathArray.reduce((curr, path) => {
            return curr[path];
        }, target);
    } catch (e) { }

    return result ? result : null;
};

const splitNested = (str) => {
    return [str].join('.').replace(/\[/g, '.').replace(/\]/g, '').split('.');
};
