import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';
import SpinnerLoader from '../../components/SpinnerLoader';
import { getApiErrorMessages } from '../../helpers/apiErrorMessages';
import { logout, loginAs } from '../../redux/auth/actions';

const { REACT_APP_API_KEY } = process.env;

class AuthAs extends Component {
    state = {
        redirectLogin: false,
        redirectDashboard: false,
    };

    componentDidMount() {
        const {
            logout,
            location,
        } = this.props;
        // We get the URI converted into a key-value object
        const uriObj = queryString.parse(location.search);

        // We log out user
        logout({skipApi: true});

        if(Object.keys(uriObj).indexOf('t') === -1 || Object.keys(uriObj).indexOf('u') === -1) {
            // If not present, we redirect straight to login
            this.setState({
                redirectLogin: true,
            });
        }
    }

    componentDidUpdate(prevProps) {
        const {
            errors,
            location,
            logged_out,
            loginAs,
            user,
        } = this.props;
        const uriObj = queryString.parse(location.search);

        if(Object.keys(uriObj).indexOf('t') > -1 && Object.keys(uriObj).indexOf('u') > -1) {
            // If we have been logged out correctly
            // We get the profile to check the token is valid
            if(prevProps.logged_out === false && logged_out === true) {
                const data = {
                    api_key: REACT_APP_API_KEY,
                    token: uriObj.t,
                    user_id: uriObj.u,
                };
                loginAs({ data });
            }

            // If we are logged out
            // And there are errors
            // Redirect to login
            // (e.g. token not valid, or attempt of force entry)
            else if(
                logged_out === true
                && (
                    !prevProps.errors
                    || prevProps.errors.length === 0
                )
                && errors
                && errors.length > 0
            ) {
                this.setState({
                    redirectLogin: true,
                });
            }

            // If we are logged out
            // And the user comes back OK,
            // It means that we have logged in correctly
            // And it's now time to redirect to dashboard
            else if(
                prevProps.logged_out === true
                && logged_out === false
                && !prevProps.user
                && user
            ) {
                this.setState({
                    redirectDashboard: true,
                });
            }
        }
    }

    render() {
        const { redirectLogin, redirectDashboard } = this.state;

        if(redirectLogin === true) {
            return <Redirect to="/login" />;
        }

        if(redirectDashboard === true) {
            return <Redirect to="/dashboard" />;
        }

        return <SpinnerLoader />;
    }
}

const mapStateToProps = (state) => {
    const {
        error,
        logged_out,
        // token,
        user,
    } = state.auth;
    const errors = getApiErrorMessages(error, false);

    return {
        errors,
        user,
        // logged_in: token ? true : false,
        logged_out: logged_out === true,
        // logging_in: logging_in === true,
    };
};

const mapDispatchToProps = {
    logout,
    loginAs,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthAs);
