import React from 'react';
import moment from 'moment';

export const momentSqlFormat = 'YYYY-MM-DD HH:mm:ss';
export const momentSqlTFormat = 'YYYY-MM-DDTHH:mm:ss';
export const momentSqlDateFormat = 'YYYY-MM-DD';
export const momentDateFormat = 'dddd DD MMMM YYYY';
export const momentTimeFormat = 'HH:mma';
export const momentDateTimeFormat = 'dddd DD MMMM YYYY HH:mma';
export const momentSqlTimeFormat = 'HH:mm:ss';

export const getFullDateText = dateTimeText => {
    return dateTimeText
        ? moment(dateTimeText, momentSqlFormat).format(momentDateFormat)
        : null
};

export const getTimeText = dateTimeText => {
    return dateTimeText
        ? moment(dateTimeText, momentSqlFormat).format(momentTimeFormat)
        : null
};

export const getOccurrencesFromRecurringDateTimeResources = resources => {
    return resources.reduce(
        (result, resource) => {
            const occurrences = resource.dates.map((occurrence, idx) => {
                return {
                    name: resource.name,
                    start_date: moment(occurrence.start_date, momentSqlFormat).format(momentSqlTFormat),
                    end_date: moment(occurrence.end_date, momentSqlFormat).format(momentSqlTFormat),
                    id: resource.id,
                };
            });

            return [
                ...result,
                ...occurrences
            ];
        },
        []
    );
};

export const formatDateTimeResources = resources => {
    return resources.reduce(
        (result, resource) => {
            return [
                ...result,
                {
                    name: resource.name,
                    start_date: moment(resource.start_date, momentSqlFormat).format(momentSqlTFormat),
                    end_date: moment(resource.end_date, momentSqlFormat).format(momentSqlTFormat),
                    id: resource.id,
                    external: resource.oauth_id,
                    provider: resource.oauth_calendar_tokens?.provider,
                    external_email: resource.oauth_calendar_tokens?.email
                }
            ];
        },
        []
    );
};

export const formatDateTimeAppointments = resources => {
    return resources.reduce((result, resource) => {
        const length_minutes = resource.viewing.main_viewing_length
            ? resource.viewing.main_viewing_length.duration_minutes
            : 60;

        const isComplete = (
            resource.status === 1
            && resource.feedback === 1
            && resource.cancel === 0
            && resource.reject === 0
        );

        const isCancelled = (
            resource.reject === 1
            || resource.cancel === 1
            || (
                resource.viewing
                && resource.viewing.cancelled === 1
            )
        )

        return [
            ...result,
            {
                id: resource.id,
                name: (
                    <span>
                        {isComplete && <i className='fa fa-check-circle mr-1' />}
                        {isCancelled && <i className='fa fa-times-circle mr-1' />}
                        {(resource.viewing && resource.viewing.property ? resource.viewing.property.postcode + ' - ' : '') + 'Appointment'}
                    </span>
                ),
                isComplete,
                isCancelled,
                start_date: (resource.viewing ? moment(resource.viewing.viewber_date, momentSqlFormat).subtract(15, 'm').format(momentSqlTFormat) : ''),
                end_date: moment(resource.viewing.viewber_date, momentSqlFormat).add(length_minutes, 'm').add(15, 'm').format(momentSqlTFormat),
            }
        ];
    }, []);
}


export const getDateTypeString = dateType => {
    return dateType == 2
        ? 'Deadline: '
        : ''
};