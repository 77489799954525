import actions from './actions';

const initialState = {
    resources: [],
    resourcesLoading: false,
    error: null
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.FIND_RESOURCE_REQUEST:
            return {
                resources: state.resources,
                resourcesLoading: true,
                errors: null
            };

        case actions.FIND_RESOURCE_SUCCESS:
            return {
                resources: state.resources,
                resourcesLoading: false,
                errors: state.errors
            }

        case actions.FIND_RESOURCE_ERROR:
            return {
                ...state,
                resourcesLoading: false,
                errors: state.errors
            };

        default:
            return state;
    }
}

export default reducer;