import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import SpinnerLoader from '../../components/SpinnerLoader';
import { getApiErrorMessages } from '../../helpers/apiErrorMessages';
import {
    getIdentity,
} from '../../redux/auth/actions';
import {
    Card,
    CardBody,
    Col,
    Container,
    Row
} from "reactstrap";
import ApiErrorAlert from "../../components/Alerts/ApiErrorAlert";
import viewberLogo from '../../assets/img/brand/viewber-logo.svg';
const {
    REACT_APP_FILE_UPLOAD_BASE_URL,
} = process.env;

class ViewberIdentity extends Component {

    constructor(props) {
        super(props);

        const urlParams = props.match.params;

        const {
            location,
        } = this.props;

        const uriObj = queryString.parse(location.search);

        this.state = {
            is_fetching: false,
            viewber_id: urlParams.viewber ? urlParams.viewber : null,
            signature: uriObj.signature ? uriObj.signature : null,
            expires: uriObj.expires ? uriObj.expires  : null,
        };
    }

    componentDidMount() {
        const { is_fetching } = this.state
        const { getIdentity } = this.props
        if (! is_fetching) {
            const { signature, expires, viewber_id } = this.state
            getIdentity({ data: { signature, expires, viewber_id }})
            this.setState({ is_fetching : true });
        }
    }

    render() {

        const { is_fetching } = this.state
        const { viewber, request_failed, errors } = this.props

        return (
            <>
                <div className="app flex-row align-items-center">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md="6">
                                <Card className="p-2">
                                    <CardBody style={{'minHeight': '300px'}}>
                                        <img
                                            src={viewberLogo}
                                            alt="Viewber logo"
                                            className="img-fluid"
                                            style={{'width': '100%','minHeight': '50px'}}
                                        />
                                        {
                                            (
                                                is_fetching === true
                                                && viewber === null
                                                && request_failed === false
                                            ) ? (
                                                <SpinnerLoader />
                                            ) : null
                                        }
                                        {
                                            request_failed === true ? (
                                                <h1>Request Failed!</h1>
                                            ) : null
                                        }
                                        {
                                            (
                                                viewber !== null
                                                && typeof viewber !== 'undefined'
                                            ) ? (
                                                <div className="col-md-12 mt-4">
                                                    <figure className="figure">
                                                        <img src={`${REACT_APP_FILE_UPLOAD_BASE_URL}${viewber.photo}`}
                                                             className="figure-img img-fluid rounded img-thumbnail"
                                                             alt=""
                                                             style={{width: '100%', 'maxHeight': '500px'}}
                                                        />
                                                    </figure>
                                                    <div className="flex items-center mt-2">
                                                        <span
                                                            style={{
                                                                'fontSize': '1.875rem',
                                                                'lineHeight': '2.25rem',
                                                                'fontWeight': '700'
                                                            }}
                                                        >{viewber.full_name}</span>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 24 24"
                                                            className="ml-2 inline-block"
                                                            style={{
                                                                'width': '1.5rem',
                                                                'height': '1.5rem',
                                                                'verticalAlign': 'sub'
                                                            }}
                                                        >
                                                            <title>Vetted</title>
                                                            <circle
                                                                cx="12"
                                                                cy="12"
                                                                r="10"
                                                                style={{'fill': '#90cdf4'}}
                                                            ></circle>
                                                            <path
                                                                d="M10 14.59l6.3-6.3a1 1 0 0 1 1.4 1.42l-7 7a1 1 0 0 1-1.4 0l-3-3a1 1 0 0 1 1.4-1.42l2.3 2.3z"
                                                                style={{'fill': '#2c5282'}}
                                                            ></path>
                                                        </svg>
                                                    </div>
                                                    <div className="pb-2 leading-none">
                                                        <p
                                                            style={{
                                                                'fontSize': '1rem',
                                                                'color': '#F97315',
                                                                'fontWeight': '700'
                                                            }}
                                                        >VIEWBER</p>
                                                    </div>
                                                </div>
                                            ) : null
                                        }
                                        <ApiErrorAlert errors={errors} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const {
        digital_identity,
        identity_request_failed,
        error
    } = state.auth

    const errors = getApiErrorMessages(error, false);

    return {
        viewber: digital_identity,
        request_failed: identity_request_failed,
        errors
    };
};

const mapDispatchToProps = {
    getIdentity
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewberIdentity);
