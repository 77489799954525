import apiRequest from '../apiRequest';

export const getNotifications = (token, options) =>
    apiRequest(`${process.env.REACT_APP_API_BASE_URL}/api/v1/notifications`, {
        ...options,
        token
    });

export const getLatestNotifications = (token, limit = 3) =>
    apiRequest(`${process.env.REACT_APP_API_BASE_URL}/api/v1/notifications`, {
        token,
        params: { limit }
    });

export const readNotification = (token, notificationId) =>
    apiRequest(`${process.env.REACT_APP_API_BASE_URL}/api/v1/notifications/read-website`, {
        method: 'POST',
        token,
        data: {id: notificationId}
    });


export const markAllRead = (token) =>
    apiRequest(`${process.env.REACT_APP_API_BASE_URL}/api/v1/notifications/mark-all-read`, {
        method: 'PUT',
        token,
    });