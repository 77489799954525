import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { momentSqlFormat } from '../../helpers/dates';
import { Badge } from 'reactstrap';

const AppointmentStatusBadge = ({ resource }) => {
    if(!resource) {
        return 'N/A';
    }

    if(resource.cancel) {
        return (
            <Badge color="danger">Cancelled</Badge>
        );
    }

    if(resource.reject || (resource.viewing && resource.viewing.status === 2)) {
        return (
            <Badge color="danger">Rejected</Badge>
        );
    }

    if(resource.viewing && resource.viewing.cancelled) {
        return (
            <Badge color="danger">Viewing Cancelled</Badge>
        );
    }

    if(resource.status === 1) {
        if(resource.feedback === 1) {
            return (
                <Badge color="primary">Feedback Sent</Badge>
            );
        }
        if(resource.viewing && resource.viewing.preferred_date_1) {
            const viewing_date = moment(resource.viewing.preferred_date_1, momentSqlFormat);
            const now = moment();
            if(viewing_date.isBefore(now)) {
                return (
                    <Badge color="warning">Submit Feedback</Badge>
                );
            }
        }

        if(resource.change_pending === 1) {
            return (
                <Badge color="warning">Action Required - Changed</Badge>
            );
        }

        if(resource.feedback === 1) {
            return (
                <Badge color="primary">Feedback Sent</Badge>
            );
        }

        return (
            <Badge color="success">Confirmed Viewing</Badge>
        );

    } else {
        if(resource.viewing && resource.viewing.preferred_date_1) {
            const viewing_date = moment(resource.viewing.preferred_date_1, momentSqlFormat);
            const now = moment();
            if(viewing_date.isBefore(now)) {
                return (
                    <Badge color="secondary">Passed</Badge>
                );
            }
        }

        if(resource.change_pending) {
            return (
                <Badge color="warning">Change Pending</Badge>
            );
        }

    }

    return (
        'N/A'
    );
};

AppointmentStatusBadge.propTypes = {
    resource: PropTypes.object,
};

export default AppointmentStatusBadge;
