import actions from './actions';

const initial_state = {
    created: false,
    current_page: 1,
    destroyed: false,
    error: null,
    fetching_resources: false,
    resource: null,
    resources: [],
    paginated_resources: {
        1: [],
    },
    total: 0,
    updated: false
};

const reducer = (state = initial_state, action) => {
    // console.log('action dispatched', action);
    switch(action.type) {
        case actions.CHANGE_PAGE_RESOURCES:
            return {
                ...state,
                current_page: action.payload.data.page
            };
        case actions.CLEAR_METADATA_RESOURCES: {
            const { data } = action.payload;
            // console.log(action.type);
            return {
                ...state,
                error: null,
                current_page: 1,
                destroyed: false,
                fetching_resources: false,
                resources: [
                    ...state.resources
                ],
                paginated_resources: (
                        data.query !== ''
                        && data.query !== null
                        && typeof data.query !== 'undefined'
                    )
                    // If I've searched before,
                    // Clear the resources so on next reload
                    // A re-fetch will be needed
                    ? {
                        1: []
                    }
                    : {
                        ...state.resources
                    }
            };
        }
        case actions.FIND_RESOURCE_REQUEST:
            // console.log('findResource dispatched');
            return {
                ...state,
                error: null
            };
        case actions.FIND_RESOURCE_SUCCESS:
            return {
                ...state,
                error: null,
                resource: action.payload.resource,
            };
        case actions.FIND_RESOURCE_ERROR:
            // console.log('findResource error:', action);
            return {
                ...state,
                error: action.error
            };
        case actions.GET_ALL_RESOURCES_REQUEST: {
            // console.log('getAllResources state', state);
            // console.log('getAllResources dispatched', action);
            return {
                ...state,
                error: null,
                fetching_resources: true,
                // If searching, reset all pages
                resources: [],
                total: 0,
            };
        }
        case actions.GET_ALL_RESOURCES_SUCCESS: {
            const { resources } = action.payload;
            return {
                ...state,
                error: null,
                fetching_resources: false,
                resources: resources,
            };
        }
        case actions.GET_ALL_RESOURCES_ERROR:
            // console.log('getAllResources error:', action);
            return {
                ...state,
                error: action.error,
                fetching_resources: false,
            };
        case actions.GET_PAGINATED_RESOURCES_REQUEST: {
            // console.log('getPaginatedResources state', state);
            // console.log('getPaginatedResources dispatched', action);
            const { data } = action.payload;
            return {
                ...state,
                error: null,
                fetching_resources: true,
                current_page: data.page,
                // If searching, reset all pages
                paginated_resources: (
                        typeof data.q !== 'undefined'
                        && data.q !== ''
                    )
                    ? {
                        1: []
                    }
                    // Otherwise reset page I'm fetching
                    : {
                        ...state.resources,
                        [data.page]: []
                    },
                total: 0,
            };
        }
        case actions.GET_PAGINATED_RESOURCES_SUCCESS: {
            const { data, total, current_page } = action.payload;
            return {
                ...state,
                current_page: current_page,
                error: null,
                fetching_resources: false,
                paginated_resources: {
                    ...state.paginated_resources,
                    [current_page]: data,
                },
                total: total,
            };
        }
        case actions.GET_PAGINATED_RESOURCES_ERROR:
            // console.log('getPaginatedResources error:', action);
            return {
                ...state,
                error: action.error,
                fetching_resources: false,
                total: 0
            };
        default:
            return state;
    }
};

export default reducer;
