import React, {useEffect, useState} from 'react';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import { HashLink } from 'react-router-hash-link';
import {BrowserRouter, Link, useHistory,  NavLink} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    Button,
    Badge,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Dropdown,
} from 'reactstrap';
import {
    getNotificationTitle,
    getNotificationText,
    getNotificationIconClassName,
} from '../../helpers/notifications';
import {
    getLatestUnreadNotifications as getLatestUnreadNotificationsAction,
    readNotification as readNotificationAction,
    markAllRead
} from '../../redux/notifications/reducer';
import {useRealTimeNotification} from '@viewberapp/notifications'

const {
    // Notification target type ids
    REACT_APP_APPOINTMENT_NOTIFICATION_TARGET_TYPE_ID,
    REACT_APP_DOCUMENT_NOTIFICATION_TARGET_TYPE_ID,
    REACT_APP_INVITATION_NOTIFICATION_TARGET_TYPE_ID,
    REACT_APP_INVOICE_NOTIFICATION_TARGET_TYPE_ID,
    REACT_APP_USER_NOTIFICATION_TARGET_TYPE_ID,
} = process.env;

const NotificationsDropdown = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = React.useState(false);
    const [readingAllLatestUnreadNotifications, setReadingAllLatestUnreadNotifications] = React.useState(false);
    const unreadNotificationCounts = useSelector(state => state.notifications.unreadNotificationCounts);
    const latestUnreadNotifications = useSelector(state => state.notifications.latestUnreadNotifications);
    const {user, token} = useSelector(state => state.auth);
    const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/notifications/ably/token`
    const [message] = useRealTimeNotification({url, user, token})

    const getLatestUnreadNotifications = React.useCallback(
        () => dispatch(getLatestUnreadNotificationsAction()),
        [dispatch]
    );

    const readNotification = React.useCallback(
        arg => dispatch(readNotificationAction(arg)),
        [dispatch]
    );
    const readAllNotifications = React.useCallback(
        () => dispatch(markAllRead()),
        [dispatch]
    );
    
    const readAll = () => {
        if (readingAllLatestUnreadNotifications) {
            return;
        } 
    
        setReadingAllLatestUnreadNotifications(true);
        readAllNotifications().then(res => {
            setReadingAllLatestUnreadNotifications(false);
            getLatestUnreadNotifications();
        }).catch(err => {
            setReadingAllLatestUnreadNotifications(false);
        })
    }

    useEffect(() => {
        getLatestUnreadNotifications()
    }, [message])

    React.useEffect(() => {
        if (isOpen) {
            getLatestUnreadNotifications();
        }
    }, [isOpen]);

    let notificationsDropdownContent;
    const totalUnreadNotifications = get(latestUnreadNotifications, 'totalUnreadNotifications', 0);

    if (latestUnreadNotifications.loading === 'pending' || readingAllLatestUnreadNotifications) {
        notificationsDropdownContent = (
            <div className='my-3 text-center'>
                <i className='fa fa-spinner fa-2x fa-spin'/>
            </div>
        )
    } else if (latestUnreadNotifications.error !== null) {
        notificationsDropdownContent = (
            <div className='my-3 text-center'>
                <p>Loading failed</p>
                <Button onClick={getLatestUnreadNotifications}>Retry</Button>
            </div>
        )
    } else {
        notificationsDropdownContent = (
            latestUnreadNotifications.data.map(notification => {
                const notificationTextMaxLength = 150;
                const iconClassName = getNotificationIconClassName(notification);
                // const title = getNotificationTitle(notification);
                let text = getNotificationText(notification);
                if (isString(text) && text.length > notificationTextMaxLength) {
                    text = text.substring(0, notificationTextMaxLength) + '...'
                }
                const className = notification.is_read
                    ? 'notification-dropdown-item read'
                    : 'notification-dropdown-item not-read'

                return (
                    <DropdownItem
                        key={`not_read_notification_${notification.id}`}
                        role="menuitem"
                        className={className}
                        onClick={() => {
                            if (notification.is_read === 0) {
                                readNotification(notification.id)
                            }
                            if (isNil(notification.target)) {
                                return
                            }
                            if (notification.target_type.id === parseInt(REACT_APP_INVITATION_NOTIFICATION_TARGET_TYPE_ID, 10)) {
                                history.push('/invitations/'+notification.target_id);
                            } else if (notification.target_type.id === parseInt(REACT_APP_APPOINTMENT_NOTIFICATION_TARGET_TYPE_ID, 10)) {
                                history.push('/appointments/'+notification.target_id);
                            } else if (notification.target_type.id === parseInt(REACT_APP_INVOICE_NOTIFICATION_TARGET_TYPE_ID, 10)) {
                                // TODO invoices notifications
                                history.push('/invoices');
                            } else if (notification.target_type.id === parseInt(REACT_APP_USER_NOTIFICATION_TARGET_TYPE_ID, 10)) {
                                history.push('/me');
                            } else if (notification.target_type.id === parseInt(REACT_APP_DOCUMENT_NOTIFICATION_TARGET_TYPE_ID, 10)) {
                                // TODO document notifications
                                history.push('/viewbers-manual');
                            }
                        }}
                    >
                        <Row>
                            <Col className="col-1">
                                <i className={`fa ${iconClassName} notification-dropdown-item`}></i>
                            </Col>
                            <Col>
                                {text}
                            </Col>
                        </Row>
                    </DropdownItem>
                )
            })
        )
    }

    return (
        <Dropdown className="nav-item" direction="down" isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
            <DropdownToggle nav>
                <i className="fa fa-bell"></i>
                {
                    totalUnreadNotifications > 0
                        ? <Badge pill color="danger">{totalUnreadNotifications}</Badge>
                        : null
                }
            </DropdownToggle>
            <DropdownMenu right style={{ width: '300px', right: 0 }}>
                <DropdownItem header tag="div" className="notification-dropdown-header">
                    {latestUnreadNotifications.data.length > 0
                        ? (
                            <div className='d-flex align-items-center justify-content-between'>
                                <span className='d-inline-block' style={{width: '33%'}}>&nbsp;</span>
                                <strong className='d-inline-block text-center' style={{width: '33%'}}>Notifications</strong>
                                <button
                                    type='button'
                                    disabled={readingAllLatestUnreadNotifications || latestUnreadNotifications.loading === 'pending'}
                                    className='btn btn-sm btn-link d-inline-block m-0 p-0'
                                    style={{width: '33%'}}
                                    onClick={readAll}
                                >
                                    Mark all as read
                                </button>
                            </div>
                        ) : (
                            <div className='text-center'>
                                <strong>Notifications</strong>
                            </div>
                        )
                    }
                </DropdownItem>
                {notificationsDropdownContent}
                <DropdownItem tag="div" className="text-center notification-dropdown-footer">
                    <HashLink to="/dashboard#notifications">See all notifications</HashLink>
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    )
}

export default NotificationsDropdown;