import actions from './actions';
import { all, takeEvery, fork } from 'redux-saga/effects';
import { sagaApiRequest } from '../helpers';
import * as viewberTypesAPI from '../../api/viewberTypes';

export function* findResourceRequest() {
    yield takeEvery(actions.FIND_RESOURCE_REQUEST, function* ({ payload }) {
        yield* sagaApiRequest({
            request: viewberTypesAPI.get,
            args: {token: payload.token},
            onSuccess: actions.FIND_RESOURCE_SUCCESS,
            onError: actions.FIND_RESOURCE_ERROR
        })
    });
}

export default function* rootSaga() {
    yield all([
        fork(findResourceRequest)
    ]);
}