import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

const toastNotification = ({
    iconClassName,
    message,
    onClose,
    title,
    type,
}) => toast(
    <div className="notification-container">
    	<div className="notification-content">
			<i className={`notification-icon fa fa-fw fa-lg ${iconClassName} notification-icon-${type}`}></i>
            {
                typeof title !== 'undefined'
                && title !== null
                && title !== ''
                ? <div className="notification-title">{title}</div>
                : null
            }
			<div className="notification-message">{message}</div>
    	</div>
    </div>
, { onClose });

toastNotification.propTypes = {
    iconClassName: PropTypes.string,
    message: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    title: PropTypes.string,
    type: PropTypes.string.isRequired
};

export default toastNotification;

export const apiFeedbackAppointmentSuccessNotification = () => toastNotification({
    iconClassName: 'fa-pencil-square-o',
    message: 'Feedback submitted successfully.',
    type: 'success',
});

export const apiFeedbackImageSuccessNotification = () => toastNotification({
    iconClassName: 'fa-picture-o',
    message: 'Image uploaded successfully.',
    type: 'success',
});

export const apiResourceAcceptSuccessNotification = ({ resourceDisplayName }) => toastNotification({
    iconClassName: 'fa-check',
    message: (resourceDisplayName ? resourceDisplayName : "Resource")
        +' accepted successfully.',
    // onClose: () => console.log('closed')
    type: 'success',
});

apiResourceAcceptSuccessNotification.propTypes = {
    resourceDisplayName: PropTypes.string
};

export const apiAppointmentAcceptChangeSuccessNotification = () => toastNotification({
    iconClassName: 'fa-check',
    message: 'Appointment Change accepted successfully.',
    // onClose: () => console.log('closed')
    type: 'success',
});

export const apiAppointmentKeysNotReceivedSuccessNotification = () => toastNotification({
    iconClassName: 'fa-check',
    message: 'Viewber Support has been notified of your Keys Not Received request.',
    // onClose: () => console.log('closed')
    type: 'success',
});

export const apiAppointmentRejectChangeSuccessNotification = () => toastNotification({
    iconClassName: 'fa-times',
    message: 'Appointment Change rejected successfully.',
    // onClose: () => console.log('closed')
    type: 'success',
});

export const apiResourceCancelSuccessNotification = ({ resourceDisplayName }) => toastNotification({
    iconClassName: 'fa-times',
    message: (resourceDisplayName ? resourceDisplayName : "Resource")
        +' cancelled successfully.',
    // onClose: () => console.log('closed')
    type: 'success',
});

apiResourceCancelSuccessNotification.propTypes = {
    resourceDisplayName: PropTypes.string
};

export const apiResourceCreateSuccessNotification = ({ resourceDisplayName }) => toastNotification({
    iconClassName: 'fa-check',
    message: (resourceDisplayName ? resourceDisplayName : "Resource")
        +' created successfully.',
    // onClose: () => console.log('closed')
    type: 'success',
});

apiResourceCreateSuccessNotification.propTypes = {
    resourceDisplayName: PropTypes.string
};

export const apiResourceDestroySuccessNotification = ({ resourceDisplayName }) => toastNotification({
    iconClassName: 'fa-trash',
    message: (resourceDisplayName ? resourceDisplayName : "Resource")
        +' removed successfully.',
    // onClose: () => console.log('closed')
    type: 'success',
});

apiResourceDestroySuccessNotification.propTypes = {
    resourceDisplayName: PropTypes.string
};

export const apiResourceRejectSuccessNotification = ({ resourceDisplayName }) => toastNotification({
    iconClassName: 'fa-times',
    message: (resourceDisplayName ? resourceDisplayName : "Resource")
        +' rejected successfully.',
    // onClose: () => console.log('closed')
    type: 'success',
});

apiResourceRejectSuccessNotification.propTypes = {
    resourceDisplayName: PropTypes.string
};

export const apiResourceUpdateSuccessNotification = ({ resourceDisplayName }) => toastNotification({
    iconClassName: 'fa-check',
    message: (resourceDisplayName ? resourceDisplayName : "Resource")
        +' updated successfully.',
    // onClose: () => console.log('closed')
    type: 'success',
});

apiResourceUpdateSuccessNotification.propTypes = {
    resourceDisplayName: PropTypes.string
};

export const apiValidationErrorsNotification = () => toastNotification({
    iconClassName: 'fa-warning',
    message: 'There were problems with the values you submitted.',
    type: 'danger',
});

export const noInternetConnectionNotification = () => toastNotification({
    iconClassName: 'fa-warning',
    message: 'You don\'t seem to be connected to the internet. Please check your internet connection and refresh the page.',
    type: 'danger',
});
