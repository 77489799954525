import React from 'react';
import { getFullDateText, getTimeText } from '../../helpers/dates';
import AppointmentStatusBadge from '../../views/Resources/AppointmentStatusBadge';
import { viewingOccursOnDateAndTime } from "../../helpers/viewings";
import isNil from "lodash/isNil";

export const resourceBaseRoute = 'appointments';

export const keyField = "id";

export const nameField = "viewing.id";

export const columns = [
    {
        dataField: 'viewing.id',
        text: 'Viewing Code',
        sort: true,
        className: 'col-md-12',
        formatter: (value) => value ? 'VV'+value : 'N/A',
    },
    {
        dataField: 'viewing.property.postcode',
        text: 'Postcode',
        sort: true,
        className: 'col-md-3 col-12',
        formatter: (value) => value ? value.toUpperCase() : 'N/A',
    },
    {
        dataField: 'viewing.viewber_date',
        text: 'Date',
        sort: true,
        className: 'col-md-4 col-12',
        formatter: (value) => value ? getFullDateText(value) : 'N/A',
    },
    {
        dataField: 'viewing.viewber_date',
        text: 'Time',
        sort: true,
        className: 'col-md-2 col-12',
        formatter: (value) => value ? getTimeText(value) : 'N/A',
        shouldRender: appointment => viewingOccursOnDateAndTime(appointment.viewing)
    },
    {
        dataField: 'resource',
        text: 'Status',
        sort: true,
        className: appointment => {
            // If we are showing the time then this will have the
            // class 'col-md-2' to allow space for the time column.
            if (isNil(appointment) || viewingOccursOnDateAndTime(appointment.viewing)) {
                return 'col-md-2 col-12'
            }
            // Otherwise if we are not showing the time then we will expand
            // this status column to fill the space of the time.
            return 'col-md-4 col-12'
        },
        formatter: (resource) => <AppointmentStatusBadge resource={resource} />,
    },
];

export const sortingOptions = [
    {
        display_name: 'Date and time',
        name: 'viewber_date',
        direction: 'desc',
    },
    {
        display_name: 'Date and time',
        name: 'viewber_date',
        direction: 'asc',
    },
    {
        display_name: 'Viewing code',
        name: 'request_id',
        direction: 'desc',
    },
    {
        display_name: 'Viewing code',
        name: 'request_id',
        direction: 'asc',
    },
];

export const defaultSortingOption = {...sortingOptions[0]};

export const searchColumns = [
    'Postcode',
    'Viewing Code',
];

export const pageSize = 10;

export const schema = {
    // display_name: {
    //     type: 'text',
    //     value: '',
    //     rules: yup.string()
    //         .required()
    //         .max(255),
    //     errors: [],
    // },
};

export default schema;
