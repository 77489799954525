import isNil from 'lodash/isNil';
import { apiRequestWithBaseUrl } from "../apiRequest";

export const find = ({id, token}) => apiRequestWithBaseUrl(`viewber-billing-types/${id}`, {
    method: 'GET',
    token
});

export const all = ({id, token}) => apiRequestWithBaseUrl(`viewber-billing-types`, {
    method: 'GET',
    token
});

export const paginate = async ({
	token,
	page,
	pageSize,
	q
}) => apiRequestWithBaseUrl('viewber-billing-types/paginate', {
    method: 'GET',
	token,
	params: {
		page,
		page_size: pageSize,
		q: !isNil(q) ? q : undefined
	}
});
