import actions from './actions';
import { updatePaginatedResourcesFromResource } from '../../helpers/paginatedResources';

const initial_state = {
    cancelled: false,
    change_accepted: false,
    change_rejected: false,
    created: false,
    destroyed: false,
    documents: [],
    current_page: 1,
    date_filtered_resources: [],
    error: null,
    rejected: false,
    feedback_submitted: false,
    fetching_resources: false,
    fetching_feedback_answers: false,
    fetching_feedback_images: false,
    fetching_feedback_no_show_questions: false,
    fetching_feedback_questions: false,
    fetching_feedback_overrun: false,
    getting_resource: false,
    getting_next_resource: false,
    keys_not_received_submitted: false,
    invoiceable_resources: [],
    next_resource: null,
    resource: null,
    feedback_answers: [],
    feedback_images: [],
    feedback_no_show_questions: [],
    feedback_questions: [],
    feedback_overrun: null,
    resources: [],
    paginated_resources: {
        1: []
    },
    total: 0,
    updated: false,
    uploaded_feedback_image: null,
    feedback_key_holding: null,
    feedback_key_holding_submitted: false,
    fetching_feedback_key_holding: false,
    submitting_feedback_key_holding: false,
};

const reducer = (state = initial_state, action) => {
    switch(action.type) {
        case actions.ACCEPT_CHANGE_RESOURCE_REQUEST:
            return {
                ...state,
                // appointment: null,
                change_accepted: false,
                error: null,
            };
        case actions.ACCEPT_CHANGE_RESOURCE_SUCCESS:
            return {
                ...state,
                appointment: action.payload.appointment,
                change_accepted: true,
                error: null,
                // We reset the paginated resources
                // so a fetch is needed to update the main list
                paginated_resources: {1: []},
                // resource: action.payload.resource,
            };
        case actions.ACCEPT_CHANGE_RESOURCE_ERROR:
            return {
                ...state,
                // appointment: null,
                change_accepted: false,
                error: action.error,
            };
        case actions.CANCEL_RESOURCE_REQUEST:
            return {
                ...state,
                error: null,
                cancelled: false,
                appointment: null,
            };
        case actions.CANCEL_RESOURCE_SUCCESS:
            return {
                ...state,
                appointment: action.payload.appointment,
                cancelled: true,
                error: null,
                // We reset the paginated resources
                // so a fetch is needed to update the main list
                paginated_resources: {1: []},
                // resource: action.payload.resource,
            };
        case actions.CANCEL_RESOURCE_ERROR:
            return {
                ...state,
                appointment: null,
                cancelled: false,
                error: action.error,
            };
        case actions.CHANGE_PAGE_RESOURCES:
            return {
                ...state,
                current_page: action.payload.data.page
            };
        case actions.CLEAR_METADATA_RESOURCES: {
            return {
                ...state,
                cancelled: false,
                current_page: 1,
                destroyed: false,
                error: null,
                fetching_resources: false,
                paginated_resources: {1: []},
                resources: [],
            };
        }
        case actions.CLEAR_METADATA_RESOURCE_EDIT:
            return {
                ...state,
                cancelled: false,
                change_accepted: false,
                change_rejected: false,
                created: false,
                error: null,
                feedback_submitted: false,
                resource: null,
                updated: false,
                uploaded_feedback_image: false,
            };
        case actions.DESTROY_RESOURCE_REQUEST:
            return {
                ...state,
                destroyed: false,
                error: null,
            };
        case actions.DESTROY_RESOURCE_SUCCESS:
            return {
                ...state,
                error: null,
                destroyed: true,
                // resource: action.payload.resource,
            };
        case actions.DESTROY_RESOURCE_ERROR:
            return {
                ...state,
                destroyed: false,
                error: action.error,
            };
        case actions.FEEDBACK_RESOURCE_REQUEST:
            return {
                ...state,
                error: null,
                feedback_submitted: false,
            };
        case actions.FEEDBACK_RESOURCE_SUCCESS:
            return {
                ...state,
                error: null,
                feedback_submitted: true,
                resource: action.payload.resource,
            };
        case actions.FEEDBACK_RESOURCE_ERROR:
            return {
                ...state,
                error: action.error,
                feedback_submitted: false,
            };
        case actions.FIND_RESOURCE_REQUEST:
            return {
                ...state,
                error: null,
                feedback_answers: [],
                feedback_images: [],
                feedback_no_show_questions: [],
                feedback_questions: [],
                getting_resource: true,
                resource: null,
            };
        case actions.FIND_RESOURCE_SUCCESS:
            return {
                ...state,
                error: null,
                getting_resource: false,
                resource: action.payload.resource,
            };
        case actions.FIND_RESOURCE_ERROR:
            return {
                ...state,
                error: action.error,
                getting_resource: false,
            };
        case actions.FIND_NEXT_RESOURCE_REQUEST:
            return {
                ...state,
                error: null,
                getting_next_resource: true,
            };
        case actions.FIND_NEXT_RESOURCE_SUCCESS:
            return {
                ...state,
                error: null,
                getting_next_resource: false,
                next_resource: action.payload.resource,
            };
        case actions.FIND_NEXT_RESOURCE_ERROR:
            return {
                ...state,
                error: action.error,
                getting_next_resource: false,
            };
        case actions.GET_FEEDBACK_ANSWERS_REQUEST: {
            return {
                ...state,
                error: null,
                fetching_feedback_answers: true,
                feedback_answers: [],
                total: 0,
            };
        }
        case actions.GET_FEEDBACK_ANSWERS_SUCCESS: {
            const { resources } = action.payload;
            return {
                ...state,
                error: null,
                fetching_feedback_answers: false,
                feedback_answers: resources,
            };
        }
        case actions.GET_FEEDBACK_ANSWERS_ERROR:
            return {
                ...state,
                error: action.error,
                fetching_feedback_answers: false,
            };

        case actions.GET_DOCUMENTS_REQUEST: {
            return {
                ...state,
                error: null,
                fetching_documents: true,
                documents: [],
            };
        }
        case actions.GET_DOCUMENTS_SUCCESS: {
            const resources = action.payload;
            return {
                ...state,
                error: null,
                fetching_documents: false,
                documents: resources,
            };
        }
        case actions.GET_DOCUMENTS_ERROR:
            return {
                ...state,
                error: action.error,
                fetching_documents: false,
                documents: [],
            };

        case actions.GET_FEEDBACK_IMAGES_REQUEST: {
            return {
                ...state,
                error: null,
                fetching_feedback_images: true,
                feedback_images: [],
                total: 0,
            };
        }
        case actions.GET_FEEDBACK_IMAGES_SUCCESS: {
            const { resources } = action.payload;
            return {
                ...state,
                error: null,
                fetching_feedback_images: false,
                feedback_images: resources,
            };
        }
        case actions.GET_FEEDBACK_IMAGES_ERROR:
            return {
                ...state,
                error: action.error,
                fetching_feedback_images: false,
            };
        case actions.GET_FEEDBACK_NO_SHOW_QUESTIONS_REQUEST: {
            return {
                ...state,
                error: null,
                fetching_feedback_no_show_questions: true,
                feedback_no_show_questions: [],
                total: 0,
            };
        }
        case actions.GET_FEEDBACK_NO_SHOW_QUESTIONS_SUCCESS: {
            const { resources } = action.payload;
            return {
                ...state,
                error: null,
                fetching_feedback_no_show_questions: false,
                feedback_no_show_questions: resources,
            };
        }
        case actions.GET_FEEDBACK_NO_SHOW_QUESTIONS_ERROR:
            return {
                ...state,
                error: action.error,
                fetching_feedback_no_show_questions: false,
            };
        case actions.GET_FEEDBACK_QUESTIONS_REQUEST: {
            return {
                ...state,
                error: null,
                fetching_feedback_questions: true,
                feedback_questions: [],
                total: 0,
            };
        }
        case actions.GET_FEEDBACK_QUESTIONS_SUCCESS: {
            const { resources } = action.payload;

            return {
                ...state,
                error: null,
                feedback_questions: resources,
                fetching_feedback_questions: false,
            };
        }
        case actions.GET_FEEDBACK_QUESTIONS_ERROR:
            return {
                ...state,
                error: action.error,
                fetching_feedback_questions: false,
            };
        case actions.GET_FEEDBACK_OVERRUN_REQUEST: {
            return {
                ...state,
                error: null,
                fetching_feedback_overrun: true,
                feedback_overrun: null,
                total: 0,
            };
        }
        case actions.GET_FEEDBACK_OVERRUN_SUCCESS: {
            const { resources } = action.payload;
            return {
                ...state,
                error: null,
                fetching_feedback_overrun: false,
                feedback_overrun: resources,
            };
        }
        case actions.GET_FEEDBACK_OVERRUN_ERROR:
            return {
                ...state,
                error: action.error,
                fetching_feedback_overrun: false,
            };
        case actions.GET_INVOICEABLE_RESOURCES_REQUEST: {
            return {
                ...state,
                error: null,
                fetching_resources: true,
                invoiceable_resources: [],
            };
        }
        case actions.GET_INVOICEABLE_RESOURCES_SUCCESS: {
            return {
                ...state,
                error: null,
                fetching_resources: false,
                invoiceable_resources: action.payload.resources,
            };
        }
        case actions.GET_INVOICEABLE_RESOURCES_ERROR:
            return {
                ...state,
                error: action.error,
                fetching_resources: false,
            };
        case actions.GET_PAGINATED_RESOURCES_REQUEST: {
            const { data } = action.payload;

            return {
                ...state,
                current_page: data.page,
                error: null,
                fetching_resources: true,
                paginated_resources: {
                    ...state.paginated_resources,
                    [data.page]: []
                },
                total: 0,
            };
        }
        case actions.GET_PAGINATED_RESOURCES_SUCCESS: {
            const { data, meta: { current_page, total } } = action.payload;
            return {
                ...state,
                current_page: current_page,
                error: null,
                fetching_resources: false,
                paginated_resources: {
                    ...state.paginated_resources,
                    [current_page]: data
                },
                total: total,
            };
        }
        case actions.GET_PAGINATED_RESOURCES_ERROR:
            return {
                ...state,
                error: action.error,
                fetching_resources: false,
                total: 0
            };
        case actions.REJECT_CHANGE_RESOURCE_REQUEST:
            return {
                ...state,
                // appointment: null,
                change_rejected: false,
                error: null,
            };
        case actions.REJECT_CHANGE_RESOURCE_SUCCESS:
            return {
                ...state,
                error: null,
                change_rejected: true,
                appointment: action.payload.appointment,
                // We reset the paginated resources
                // so a fetch is needed to update the main list
                paginated_resources: {
                    1: [],
                },
                // resource: action.payload.resource,
            };
        case actions.REJECT_CHANGE_RESOURCE_ERROR:
            return {
                ...state,
                // appointment: null,
                change_rejected: false,
                error: action.error,
            };
        case actions.SUBMIT_KEYS_NOT_RECEIVED_RESOURCE_REQUEST:
            return {
                ...state,
                keys_not_received_submitted: false,
            };
        case actions.SUBMIT_KEYS_NOT_RECEIVED_RESOURCE_SUCCESS: {
            return {
                ...state,
                keys_not_received_submitted: true,
            };
        }
        case actions.SUBMIT_KEYS_NOT_RECEIVED_RESOURCE_ERROR:
            return {
                ...state,
                error: action.error,
                keys_not_received_submitted: false,
            };
        case actions.UPDATE_RESOURCE_REQUEST:
            return {
                ...state,
                created: false,
                error: null,
                updated: false,
            };
            case actions.UPDATE_RESOURCE_SUCCESS: {
                const { resource } = action.payload;

                return {
                    ...state,
                    error: null,
                    paginated_resources: updatePaginatedResourcesFromResource(state.paginated_resources, resource),
                    resource: resource,
                    updated: true,
                };
            }
            case actions.UPDATE_RESOURCE_ERROR:
                return {
                    ...state,
                    error: action.error,
                    updated: false,
                };
        case actions.UPLOAD_FEEDBACK_IMAGE_REQUEST: {
            return {
                ...state,
                error: null,
                uploaded_feedback_image: null,
            };
        }
        case actions.UPLOAD_FEEDBACK_IMAGE_SUCCESS: {
            return {
                ...state,
                error: null,
                uploaded_feedback_image: action.payload.image,
                // user: action.payload.resource,
            };
        }
        case actions.UPLOAD_FEEDBACK_IMAGE_ERROR: {
            return {
                ...state,
                error: action.error,
                uploaded_feedback_image: null,
            };
        }
        case actions.GET_DATE_FILTERED_RESOURCES_REQUEST: {
            return {
                ...state,
                date_filtered_resources: [],
                error: null,
            };
        }
        case actions.GET_DATE_FILTERED_RESOURCES_SUCCESS: {
            const { resources } = action.payload;

            return {
                ...state,
                date_filtered_resources: resources,
                error: null,
            };
        }
        case actions.GET_DATE_FILTERED_RESOURCES_ERROR:
            return {
                ...state,
                error: action.error,
            };
        case actions.REJECT_RESOURCE_REQUEST:
            return {
                ...state,
                created: false,
                error: null,
                rejected: false
            };
        case actions.REJECT_RESOURCE_SUCCESS: {
            const { resource } = action.payload;
            return {
                ...state,
                error: null,
                rejected: true,
                resource: resource,
            };
        }
        case actions.REJECT_RESOURCE_ERROR:
            return {
                ...state,
                error: action.error,
                rejected: false
            };
        case actions.GET_FEEDBACK_KEY_HOLDING_REQUEST:
            return {
                ...state,
                error: null,
                fetching_feedback_key_holding: true,
                feedback_key_holding: null,
                feedback_key_holding_submitted: false,
                submitting_feedback_key_holding: false,
            };
        case actions.GET_FEEDBACK_KEY_HOLDING_SUCCESS: {
            const { resource } = action.payload;
            return {
                ...state,
                error:                          null,
                fetching_feedback_key_holding:  false,
                feedback_key_holding:           resource,
                feedback_key_holding_submitted: !!resource,
                submitting_feedback_key_holding: false,
            };
        }
        case actions.GET_FEEDBACK_KEY_HOLDING_ERROR:
            return {
                ...state,
                error: action.error,
                fetching_feedback_key_holding: false,
                feedback_key_holding: null,
                feedback_key_holding_submitted: false,
                submitting_feedback_key_holding: false,
            };
        case actions.SUBMIT_FEEDBACK_KEY_HOLDING_REQUEST:
            return {
                ...state,
                error: null,
                feedback_key_holding_submitted: false,
                submitting_feedback_key_holding: true,
            };
        case actions.SUBMIT_FEEDBACK_KEY_HOLDING_SUCCESS: {
            const { resource } = action.payload;
            
            return {
                ...state,
                error:                          null,
                feedback_key_holding_submitted: true,
                feedback_key_holding:           resource,
                submitting_feedback_key_holding: false,
            };
        }
        case actions.SUBMIT_FEEDBACK_KEY_HOLDING_ERROR:
            return {
                ...state,
                error: action.error,
                feedback_key_holding_submitted: false,
                submitting_feedback_key_holding: false,
            };
        default:
            return state;
    }
};

export default reducer;
