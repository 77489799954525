import { all, takeEvery, fork } from 'redux-saga/effects';
import actions from './actions';
import { sagaApiRequest } from '../helpers';
import * as documentsAPI from '../../api/documents';

export function* getToReadResourcesRequest() {
    yield takeEvery(actions.GET_TO_READ_RESOURCES_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: documentsAPI.toRead,
            args: payload.data,
            onSuccess: actions.GET_TO_READ_RESOURCES_SUCCESS,
            onError: actions.GET_TO_READ_RESOURCES_ERROR
        });
    });
}

export default function* rootSaga() {
    yield all([
        fork(getToReadResourcesRequest),
    ]);
}
