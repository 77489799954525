import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { init } from '@sentry/browser';
import { ToastContainer } from 'react-toastify';
import { DefaultLayout } from './containers';
import ErrorBoundary from './ErrorBoundary';
import MaintenanceChecker from './MaintenanceChecker';
import GuestComponent from './components/GuestComponent';
import {
    ViewberIdentity,
    Auth,
    AuthAs,
    Login,
    TwoFactorPage,
    Page404,
    PasswordReset,
    PasswordResetEmail,
} from './views/Pages';
import './scss/style.scss';

const {
    NODE_ENV,
    REACT_APP_SENTRY_KEY,
    REACT_APP_SENTRY_PROJECT_ID
} = process.env;

class App extends Component {
    componentDidMount() {
        if(
            NODE_ENV === 'production'
            && typeof REACT_APP_SENTRY_KEY !== 'undefined'
            && REACT_APP_SENTRY_KEY !== null
            && REACT_APP_SENTRY_KEY !== ''
            && typeof REACT_APP_SENTRY_PROJECT_ID !== 'undefined'
            && REACT_APP_SENTRY_PROJECT_ID !== null
            && REACT_APP_SENTRY_PROJECT_ID !== ''
        ) {
            // Initialize Sentry error tracking
            init({
                dsn: `https://${REACT_APP_SENTRY_KEY}@sentry.io/${REACT_APP_SENTRY_PROJECT_ID}`
            });
        }
    }

    render() {
        const { external_four_zero_four } = this.props;

        return (
            <>
                <BrowserRouter>
                    <Switch>
                        <Route
                            exact
                            path="/auth/identity/:viewber"
                            name="ViewberIdentity"
                            render={(props) => (
                                <ErrorBoundary>
                                    <MaintenanceChecker>
                                        <ViewberIdentity {...props} />
                                    </MaintenanceChecker>
                                </ErrorBoundary>
                            )}
                        />
                        <Route exact path="/login" name="Login Page" render={(props) => (
                            <ErrorBoundary>
                                <GuestComponent>
                                    <MaintenanceChecker>
                                        <Login {...props} />
                                    </MaintenanceChecker>
                                </GuestComponent>
                            </ErrorBoundary>
                        )} />
                        <Route exact path="/2fa" name="2FA Page" render={props => (
                            <ErrorBoundary>
                                <GuestComponent>
                                    <MaintenanceChecker>
                                        <TwoFactorPage {...props} />
                                    </MaintenanceChecker>
                                </GuestComponent>
                            </ErrorBoundary>
                        )} />
                        <Route exact path="/auth" name="Auth" render={(props) => (
                            <ErrorBoundary>
                                <MaintenanceChecker>
                                    <Auth {...props} />
                                </MaintenanceChecker>
                            </ErrorBoundary>
                        )} />
                        <Route exact path="/auth-as" name="Auth As" render={(props) => (
                            <ErrorBoundary>
                                <MaintenanceChecker>
                                    <AuthAs {...props} />
                                </MaintenanceChecker>
                            </ErrorBoundary>
                        )} />
                        <Route
                            exact
                            path="/password/reset/:token/:email"
                            name="Password Reset Page"
                            render={props => (
                                <ErrorBoundary>
                                    <GuestComponent>
                                        <MaintenanceChecker>
                                            <PasswordReset {...props} />
                                        </MaintenanceChecker>
                                    </GuestComponent>
                                </ErrorBoundary>
                            )}
                        />
                        <Route
                            exact
                            path="/password/reset"
                            name="Password Reset Request Page"
                            render={props => (
                                <ErrorBoundary>
                                    <GuestComponent>
                                        <MaintenanceChecker>
                                            <PasswordResetEmail {...props} />
                                        </MaintenanceChecker>
                                    </GuestComponent>
                                </ErrorBoundary>
                            )}
                        />
                        {
                            external_four_zero_four === true
                                ? <Page404 />
                                : <Route path="/" name="Home" component={DefaultLayout} />
                        }
                    </Switch>
                    <ToastContainer
                        hideProgressBar
                        bodyClassName="color-black"
                    />
                </BrowserRouter>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { external_four_zero_four } = state.auth;

    return {
        external_four_zero_four,
    };
};

export default connect(
    mapStateToProps
)(App);
