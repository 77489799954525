import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as travelMethodsApi from '../../api/travelMethods'

export const getTravelMethods = createAsyncThunk(
  'travelMethods/getTravelMethods',
  async (arg, {getState, requestId}) => {
      const { currentRequestId, loading } = getState().travelMethods

      if (loading !== 'pending' || requestId !== currentRequestId) {
        return
      }

      const response = await travelMethodsApi.getTravelMethods(getState().auth.token)

      return response.data.data
  }
)

const travelMethodsSlice = createSlice({
  name: 'travelMethods',
  initialState: {
      data: [],
      loading: 'idle',
      currentRequestId: undefined,
      error: null
  },
  reducers: {},
  extraReducers: {
    [getTravelMethods.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getTravelMethods.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.data = action.payload
        state.currentRequestId = undefined
      }
    },
    [getTravelMethods.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.error = action.error
        state.currentRequestId = undefined
      }
    }
  }
})

const { reducer } = travelMethodsSlice

export default reducer