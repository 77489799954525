import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
    Button,
    Col,
    Container,
    Row
} from 'reactstrap';
import { externalFourZeroFourReset, externalFourZeroFourSet } from '../../redux/auth/actions'

class Page404 extends Component {
    state = {
        redirect_home: false,
    };

    constructor(props) {
        super(props);

        this.redirectHome = this.redirectHome.bind(this);
    }

    componentDidMount() {
        const { externalFourZeroFourSet, token } = this.props;

        if(!token) {
            externalFourZeroFourSet();
        }
    }

    redirectHome() {
        const { externalFourZeroFourReset } = this.props;

        this.setState(
            { redirect_home: true },
            () => externalFourZeroFourReset()
        );
    }

    render() {
        const { token } = this.props;
        const { redirect_home } = this.state;

        if(redirect_home === true) {
            if(token) {
                return <Redirect push to="/" />;
            } else {
                return <Redirect push to="/login" />;
            }
        }

        return (
            <div className="app flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="6">
                            <div className="clearfix">
                                <h1 className="float-left display-3 mr-4">404</h1>
                                <h4 className="pt-3">Oops! You're lost.</h4>
                                <p className="text-muted float-left">The page you are looking for was not found.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col md="6">
                            <Button className="btn-home btn-brand" onClick={this.redirectHome}>
                                <i className="fa fa-home"></i>
                                <span>Home</span>
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { token } = state.auth;

    return {
        token,
    };
};

const mapDispatchToProps = {
    externalFourZeroFourReset,
    externalFourZeroFourSet,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Page404);
