import get from 'lodash/get';
import isNil from 'lodash/isNil';
import actions from './actions';

const initial_state = {
    error: null,
    external_four_zero_four: false,
    logging_in: false,
    logging_out: false,
    logged_out: false,
    password_reset: false,
    password_reset_requested: false,
    registering: false,
    updated_profile: false,
    token: null,
    user: null,
    digital_identity: null,
    identity_request_failed: false,
    must_complete_mfa: false,
};

const reducer = (state = initial_state, action) => {
    switch(action.type) {
        case actions.CLEAR_METADATA_PROFILE:
            return {
                ...state,
                error: null,
                logging_in: false,
                logging_out: false,
                registering: false,
                updated_profile: false,
            };

        case actions.EXTERNAL_FOUR_ZERO_FOUR_RESET:
            return {
                ...state,
                external_four_zero_four: false,
            };

        case actions.EXTERNAL_FOUR_ZERO_FOUR_SET:
            return {
                ...state,
                external_four_zero_four: true,
            };

        case actions.GET_PROFILE_REQUEST:
            return {
                ...state,
                error: null,
                user: null,
            };

        case actions.GET_PROFILE_SUCCESS:
            return {
                ...state,
                error: null,
                user: action.payload.resource,
            };

        case actions.GET_PROFILE_ERROR:
            return {
                ...state,
                error: action.error,
                user: null,
            };

        case actions.LOGIN_ERROR_RESET:
            return {
                ...state,
                error: null,
            };

        case actions.VERIFY_TWO_FACTOR_CODE_SUCCESS: {
            const { must_complete_mfa, token } = action.payload;
            return {
                ...state,
                verifying: false,
                error: null,
                must_complete_mfa,
                token
            };
        }
        case actions.VERIFY_TWO_FACTOR_CODE_ERROR: {
            return {
                ...state,
                error: action.error,
                verifying: false,
            };
        }
        case actions.VERIFY_TWO_FACTOR_CODE_REQUEST: {
            return {
                ...state,
                verifying: true,
            };
        }
        case actions.RESEND_TWO_FACTOR_CODE_ERROR: {
            return {
                ...state,
                error: action.error,
                must_complete_mfa: false,
                logged_out: true,
                user: null,
                token: null,
            };
        }
        case actions.LOGIN_REQUEST:
            return {
                ...state,
                error: null,
                logged_out: false,
                logging_in: true,
                user: null,
            };

        case actions.LOGIN_SUCCESS: {
            const { resource, token, must_complete_mfa, messages } = action.payload;
            const hasLoginErrors = typeof messages === 'object' && messages[0] !== undefined && messages[0].includes('The details you have provided do not match our records.')

            return {
                ...state,
                error: hasLoginErrors ? messages[0] : null,
                logging_in: false,
                user: resource ? resource : null,
                token,
                must_complete_mfa
            };
        }

        case actions.LOGIN_ERROR:
            return {
                ...state,
                error: action.error,
                logging_in: false,
                user: null,
            };

        case actions.LOGIN_AS_REQUEST:
            return {
                ...state,
                error: null,
                logging_in: true,
                user: null,
            };

        case actions.LOGIN_AS_SUCCESS: {
            const { resource, token } = action.payload;
            return {
                ...state,
                error: null,
                logged_out: false,
                logging_in: false,
                user: resource ? resource : null,
                token,
            };
        }

        case actions.LOGIN_AS_ERROR:
            return {
                ...state,
                error: action.error,
                logging_in: false,
                user: null,
            };

        case actions.LOGOUT_REQUEST:
            return {
                ...state,
                error: null,
                logging_out: true,
            };

        case actions.LOGOUT_SUCCESS:
            return {
                ...state,
                logging_out: false,
                logged_out: true,
                user: null,
                token: null,
                error: (action.payload && action.payload.error)
                    ? action.payload.error
                    : null,
            };

        case actions.PASSWORD_RESET_EMAIL_REQUEST:
            return {
                ...state,
                error: null,
                password_reset_requested: false,
            };

        case actions.PASSWORD_RESET_EMAIL_SUCCESS:
            return {
                ...state,
                error: null,
                password_reset_requested: true,
            };

        case actions.PASSWORD_RESET_EMAIL_ERROR:
            return {
                ...state,
                error: action.error,
                password_reset_requested: false,
            };

        case actions.PASSWORD_RESET_REQUEST:
            return {
                ...state,
                error: null,
                password_reset: false,
            };

        case actions.PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                error: null,
                password_reset: true,
            };

        case actions.PASSWORD_RESET_ERROR:
            return {
                ...state,
                error: action.error,
                password_reset: false,
            };

        case actions.REGISTER_ERROR_RESET:
            return {
                ...state,
                error: null,
            };

        case actions.REGISTER_REQUEST:
            return {
                ...state,
                error: null,
                logged_out: false,
                registering: true,
            };

        case actions.REGISTER_SUCCESS:
            // Destructure object into user and the rest
            // Which will be the payload
            const { resource, ...token_payload } = action.payload;
            return {
                ...state,
                error: null,
                registering: false,
                user: resource,
                token: token_payload,
            };

        case actions.REGISTER_ERROR:
            return {
                ...state,
                error: action.error,
                registering: false,
            };

        case actions.REPLACE_TOKEN:
            return {
                ...state,
                token: action.payload.data.token,
            };

        case actions.SET_READ_SUZY_WEBSITE_REQUEST:
            // As the action is picked up by the related saga
            // There's no need to mutate the internal state
            // Until the request comes back with the actual updated resource
            return state;

        case actions.SET_READ_SUZY_WEBSITE_SUCCESS: {
            const { resource } = action.payload;
            return {
                ...state,
                user: {
                    ...resource,
                },
            };
        }
        case actions.SET_READ_SUZY_WEBSITE_ERROR:
            // We silently fail for this error,
            // as it's not paramount for the user to have a feedback of the error
            return state;

        case actions.UPDATE_PROFILE_REQUEST:
            return {
                ...state,
                error: null,
                updated_profile: false,
            };

        case actions.UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                error: null,
                updated_profile: true,
                user: action.payload.resource,
            };

        case actions.UPDATE_PROFILE_ERROR:
            return {
                ...state,
                error: action.error,
                updated_profile: false,
            };

        case actions.UPLOAD_PROFILE_PICTURE_REQUEST: {
            return {
                ...state,
                error: null,
                // updated_profile: false,
            };
        }

        case actions.UPLOAD_PROFILE_PICTURE_SUCCESS: {
            return {
                ...state,
                error: null,
                // updated_profile: true,
                // user: action.payload.resource,
            };
        }

        case actions.UPLOAD_PROFILE_PICTURE_ERROR: {
            return {
                ...state,
                error: action.error,
                // updated_profile: false,
            };
        }

        case actions.GET_IDENTITY_REQUEST: {
            return {
                ...state,
                error: null,
                digital_identity: null,
                identity_request_failed: false
            };
        }

        case actions.GET_IDENTITY_SUCCESS: {
            return {
                ...state,
                error: null,
                digital_identity: action.payload.resource,
                identity_request_failed: false
            };
        }

        case actions.GET_IDENTITY_ERROR: {
            return {
                ...state,
                error: action.error,
                digital_identity: null,
                identity_request_failed: true
            };
        }
        
        default:
            return state;
    }
};

export default reducer;
