import axios from '../axios';
import { apiRequestWithBaseUrl } from "../apiRequest";

export const login = data => apiRequestWithBaseUrl('auth/login', {
    method: 'POST',
    data
});

export const loginAs = data => apiRequestWithBaseUrl('auth/login-as', {
    method: 'POST',
    data
});

export const passwordReset = data => apiRequestWithBaseUrl('auth/password/reset', {
    method: 'POST',
    data
});

export const passwordResetEmail = data => apiRequestWithBaseUrl('auth/password/email', {
    method: 'POST',
    data
});

export const register = data => apiRequestWithBaseUrl('auth/register', {
    method: 'POST',
    data
});

export const profile = ({token}) => apiRequestWithBaseUrl('auth/me', {
    method: 'GET',
    token
});

export const updateProfile = ({token, ...rest}) => apiRequestWithBaseUrl('auth/me', {
    method: 'PUT',
    token,
    data: rest
});

export const readSuzy = ({token, ...rest}) => apiRequestWithBaseUrl('auth/me', {
    method: 'PUT',
    token,
    data: rest
});

export const identity = ({viewber_id, expires, signature}) => apiRequestWithBaseUrl(`auth/identity/${viewber_id}`, {
    method: 'GET',
    params: {
        expires,
        signature,
    }
});

export const twoFactorCodeVerify = ({token, ...rest}) => apiRequestWithBaseUrl(`auth/2fa/verify`, {
    method: 'POST',
    token,
    data: {...rest, token}
});

export const twoFactorCodeResend = ({token, ...rest}) => apiRequestWithBaseUrl(`auth/2fa/resend`, {
    method: 'POST',
    token,
    body: rest
});

export const uploadProfilePicture = async data => {
    const formData = new FormData();
    
    Object.entries(data).forEach(([name, value]) => {
        formData.append(name, value);
    });
    return await fetch(process.env.REACT_APP_FILE_UPLOAD_HANDLER, {
        method: 'POST',
        body: formData,
    }).then(response => response.json());
};

export const logout = ({token}) => apiRequestWithBaseUrl('auth/logout', {
    method: 'POST',
    token
});