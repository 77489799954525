import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Button, ButtonGroup, Col, Row } from 'reactstrap';

const PageTitle = ({
    actions,
    text,
    wrap,
}) => {
    // If there are no actions I will set the different width,
    // otherwise default to full width
    const titleClassName = actions && actions.length > 0 && !wrap
        ? 'col-12 col-lg-6'
        : 'col-12';

    const actionsClassName = actions
        ? (
            wrap
                ? 'col-12'
                : 'col-12 col-lg-6'
        )
        : null;

    return (
        <>
            <Helmet>
                <title>{`${text}`} | Viewber Dashboard</title>
            </Helmet>
            <Row>
                <Col className={titleClassName}>
                    <h1 className="page-title">
                        <span>{text}</span>
                    </h1>
                </Col>
                {actions && (
                    <Col className={actionsClassName}>
                        <ButtonGroup className="d-flex">
                            {actions.map((action, idx) => {
                                if(action.type === 'button') {
                                    return (
                                        <PageTitleButton
                                            key={idx}
                                            className={action.className}
                                            color={action.color}
                                            disabled={action.disabled}
                                            iconClassName={action.iconClassName}
                                            onClick={action.onClick}
                                            title={action.title}
                                        />
                                    );
                                }

                                if(action.type === 'link') {
                                    return (
                                        <PageTitleLink
                                            key={idx}
                                            className={action.className}
                                            href={action.href}
                                            iconClassName={action.iconClassName}
                                            title={action.title}
                                        />
                                    );
                                }

                                return null;
                            })}
                        </ButtonGroup>
                    </Col>
                )}
            </Row>
        </>
    );
};

PageTitle.propTypes = {
    actions: PropTypes.array,
    text: PropTypes.string,
    wrap: PropTypes.bool,
};

export const PageTitleButton = ({
    className,
    color,
    disabled,
    iconClassName,
    onClick,
    title,
}) => (
    <Button
        className={`btn btn-block mb-4 p-2 ${className}`}
        color={color}
        disabled={disabled}
        onClick={onClick}
        title={title}
        aria-label={title}
    >
        <i className={iconClassName}></i>
        {' '}
        {title}
    </Button>
);

PageTitleButton.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    iconClassName: PropTypes.string,
    onClick: PropTypes.func,
    title: PropTypes.node,
};

export const PageTitleLink = ({
    className,
    href,
    iconClassName,
    title,
}) => (
    <Link to={href} aria-label={title} className={`btn btn-block mb-4 p-2 ${className}`}>
        <i className={iconClassName}></i>
        {' '}
        {title}
    </Link>
);

PageTitleLink.propTypes = {
    className: PropTypes.string,
    href: PropTypes.string,
    iconClassName: PropTypes.string,
    title: PropTypes.node,
};

export default PageTitle;
